import { useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from '@/styles/Waitlist.module.scss'
import Colors from '@/styles/Colors.module.scss'
import { dayjs } from '@/utils/dayjs'
import {
  checkPossibleArrayValue,
  hidePhoneNumber,
} from '@/utils/helper-functions'
import {
  getSelectedObjectById,
  formatPhoneNumber,
} from '@/utils/util-functions'
import useIsTouchable from '@/hooks/useIsTouchable'
import { compareTimeDiffs, createHMS } from '@/utils/wait-times'
import AllWaitlistEntryModals from '@/components/pages/waitlist/WaitlistEntryModal/AllWaitlistEntryModals'
import WaitlistEntryActions from '@/components/pages/waitlist/WaitlistEntries/EntryParts/WaitlistEntryActions'
import WaitlistEntryCustomFieldAction from '@/components/pages/waitlist/WaitlistEntries/WaitlistEntryCustomFieldAction'
import { PartyStatusEnum } from '@/consts/PartyStatusEnum'
import { getTimeElapsed } from '@/utils/wait-times'
import {
  useDesktopUpQuery,
  usePhoneOnlyQuery,
} from '@/utils/responsive-queries'
import { useTypedSelector } from '@/store/index'
import { Party } from '@/api/interface/party'
import { Location } from '@/api/interface/location'
import { Business } from '@/api/interface/business'
import { PhoneOff } from 'react-feather'
import useTimezone from '@/hooks/useTimezone'
import LinePositionBadge from './EntryParts/LinePositionBadge'
import WaitlistEntryWaitTime from './EntryParts/WaitlistEntryWaitTime'
import { Calendar } from 'react-feather'
import QueueName from './EntryParts/QueueName'

interface Props {
  party: Party
  actions: Record<string, Function>
  selectedLocation: Location
  business: Business
}

function WaitlistEntry({ party, actions, selectedLocation, business }: Props) {
  const entryQueue = useMemo(
    () => getSelectedObjectById(party.queueId, selectedLocation?.enabledQueues),
    [party, selectedLocation]
  )
  const { isHistory } = useTypedSelector(
    (state) => state.pages.waitlist.filters
  )

  const { isFiltered } = useTypedSelector((state) => state.pages.waitlist)

  const [showPartyActionModal, setShowPartyActionModal] = useState(null)
  const [isPhoneVisible, setIsPhoneVisible] = useState(false)
  const timezone = useTimezone()

  const isPhoneUser = usePhoneOnlyQuery()

  const updatedDiff = useMemo(() => {
    if (!timezone || !party?.updatedAt) return 0
    return getTimeElapsed(
      dayjs().tz(timezone).local(),
      dayjs.utc(party.updatedAt).tz(timezone).local()
    )
  }, [timezone, party])

  const {
    currentBusinessWaitTime,
    currentCustomerWaitTime,
    gracePeriod = 300,
    quotedWaitTime,
  } = party

  const relativeNow = dayjs().tz(
    selectedLocation?.timezone || business?.timezone
  )

  const iGracePeriod = createHMS(relativeNow, gracePeriod)
  const iQuotedWaitTime = createHMS(relativeNow, quotedWaitTime)
  const businessWaitTime = createHMS(relativeNow, currentBusinessWaitTime)
  const customerWaitTime = createHMS(relativeNow, currentCustomerWaitTime)

  const isGracePeriod =
    party.status === 'notified' || party.status === 'returning'

  const guestWaitTimesValues = useMemo(
    () => ({
      value1: isGracePeriod ? businessWaitTime : customerWaitTime,
      value2: isGracePeriod ? iGracePeriod : iQuotedWaitTime,
      hasElapsed: isGracePeriod
        ? compareTimeDiffs(businessWaitTime, iGracePeriod) > 0
        : compareTimeDiffs(customerWaitTime, iQuotedWaitTime) > 0,
    }),
    [
      businessWaitTime,
      customerWaitTime,
      isGracePeriod,
      iGracePeriod,
      iQuotedWaitTime,
    ]
  )

  const phoneDisplay = useMemo(
    () =>
      isPhoneVisible
        ? formatPhoneNumber(party.guest.phone)
        : hidePhoneNumber(formatPhoneNumber(party?.guest?.phone)),
    [isPhoneVisible, party]
  )

  const activeCustomFields = useMemo(
    () =>
      party.customFields
        .map((pcf) => {
          if (!checkPossibleArrayValue(pcf.value)) return null

          return pcf.options?.length > 0
            ? {
                ...pcf,
                options: pcf.options?.filter((x) =>
                  Array.isArray(pcf.value)
                    ? pcf.value?.find((v) => v === x.id)
                    : pcf.value === x.id
                ),
              }
            : pcf
        })
        .filter((x) => !!x),
    [party.customFields]
  )

  //* there's room to show more custom fields on desktop
  const isDesktop = useDesktopUpQuery()

  const maxCfWithNotes = isDesktop ? 3 : 1
  const maxCfWithoutNotes = maxCfWithNotes + 1

  // responsive settings
  const isTouchable = useIsTouchable()

  //   // swiped handlers
  //   const [swiped, setSwiped] = useState(false)
  //   const swipeableHandlers = useSwipeable({
  //     delta: 10, // min distance(px) before a swipe starts
  //     preventDefaultTouchmoveEvent: false, // call e.preventDefault
  //     trackTouch: true, // track touch input
  //     trackMouse: false, // track mouse input
  //     rotationAngle: 0, // set a rotation angle
  //     onSwipedLeft: () => setSwiped(true),
  //     onSwipedRight: () => setSwiped(false),
  //     onSwiped: () => {
  //       if (!window) {
  //         return
  //       }
  //       window.dispatchEvent(new Event("resize"))
  //     },
  //   })

  return (
    <>
      <div
        id="waitlist-entry-container"
        className={classNames(
          styles.entryContainer,
          styles[`entryContainer-${party.status}`]
        )}
      >
        {party?.isBooking ? (
          <span className={styles['corner-badge__isBooking']}>
            <span className={styles['corner-badge__icon-wrapper']}>
              <Calendar color="white" size={14} />
            </span>
          </span>
        ) : null}
        <section
          id="waitlist-entry-section-one"
          className={classNames(styles.entryGuestSection)}
        >
          <p id="waitlist-entry-name" className={classNames(styles.entryName)}>
            {party.guest?.name}
          </p>
          <div
            id="waitlist-entry-status-queue-name"
            className={classNames(styles.entryQueueName)}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <QueueName
              queueName={entryQueue?.name}
              party={party}
            />
            {party?.position > 0 && !isHistory && (
              <LinePositionBadge linePosition={party.position} party={party} />
            )}
          </div>
          <p
            id="waitlist-entry-status-name"
            className={classNames(
              styles.entryStatus,
              styles[`entryStatus-${party.status}`]
            )}
          >
            {PartyStatusEnum[party.status]}
            <span>
              {party.status === 'notified' && party.guest.phone && (
                <p
                  id="waitlist-entry-status-notify-count"
                  className={classNames(styles.entryNotifyCount)}
                >
                  {party.notificationHistoryReadyCount}
                </p>
              )}
              {party.status === 'notified' && !party.guest.phone && (
                <p
                  id="waitlist-entry-status-notify-count"
                  className={classNames(styles.entryNotifyCount)}
                >
                  <PhoneOff style={{ padding: 4 }} />
                </p>
              )}
            </span>
          </p>
          {party.guest.notes && (
            <p
              id="waitlist-entry-notes"
              className={classNames(styles.entryNotes)}
            >
              {party.guest.notes}
            </p>
          )}
        </section>
        <section
          id="waitlist-entry-section-two"
          className={classNames(styles.entryFieldsSection)}
        >
          <WaitlistEntryCustomFieldAction party={party} />
        </section>
        <section
          id="waitlist-entry-section-three"
          className={classNames(styles.entryWaitSection)}
        >
          <WaitlistEntryWaitTime
            party={party}
            isHistory={isHistory}
            updatedDiff={updatedDiff}
            guestWaitTimesValues={guestWaitTimesValues}
            timezone={timezone}
          />
        </section>
        <section
          id="waitlist-entry-actions-section"
          className={classNames('wlEntryActions', styles.entryActionsSection)}
        >
          <WaitlistEntryActions
            party={party}
            setShowPartyActionModal={setShowPartyActionModal}
          />
        </section>
      </div>

      {showPartyActionModal !== null && (
        <AllWaitlistEntryModals
          actions={{
            mutateParties: actions.mutateParties,
            onSubmitUpdate: actions.onSubmitUpdate,
          }}
          party={party}
          showPartyActionModal={showPartyActionModal}
          setShowPartyActionModal={setShowPartyActionModal}
        />
      )}
    </>
  )
}

export default WaitlistEntry
