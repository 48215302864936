import styles from "@/styles/Pill.module.css"
import colors from "@/styles/Colors.module.scss"
import classNames from "classnames"
import { useMobileOrTabletQuery } from "@/utils/responsive-queries"
import { useTypedSelector } from "@/store/index"
import { Queue } from "@/api/interface/queue"

interface Props {
  queue: Partial<Queue>
  count: number
  time: string
  selectedQueue: Partial<Queue>
  handleSelectQueue: (queue: Partial<Queue>) => void
}

export default function QueueStats({
  queue,
  count,
  time,
  selectedQueue,
  handleSelectQueue,
}: Props) {
  const isMobileOrTablet = useMobileOrTabletQuery()

  const { isHistory } = useTypedSelector(
    (state) => state.pages.waitlist.filters
  )

  return (
    <div
      className={classNames("me-4 mt-2 mt-lg-0")}
    >
      <button
        className={classNames(
          styles.pill,
          selectedQueue?.name === queue?.name && styles.active,
          "mb-2 me-0"
        )}
        onClick={() => {
          handleSelectQueue({ name: queue.name, id: queue.id })
        }}
      >
        {queue?.name}
      </button>
      <div className="d-flex justify-content-center">
        <p
          className={classNames(colors.twilight, "mb-0 font-semibold")}
          style={{ flex: "none" }}
        >
          {count === Infinity ? "25+" : count || 0}
        </p>
        {!isHistory && (
          <>
            <span
              className={colors["gray-light"]}
              style={{ flex: "none", margin: "0 0.5rem" }}
            >
              |
            </span>
            <p style={{ flex: "none" }}>{time}</p>
          </>
        )}
      </div>
    </div>
  )
}
