import { useMemo } from 'react'
import useWaitTimesByLocationId from './api-hooks/useWaitTimesByLocationId'
import { Party } from '@/api/interface/party'
import { getCounterSuffix } from '@/utils/line-position-utils'
import useWaitlistUpdates from './useWaitlistUpdates'
import useBusiness from './api-hooks/useBusiness'

export const generateLinePositionOptions = (partiesCount: number) => {
  if (!partiesCount) return undefined

  const positionsArr = [...Array(partiesCount + 1).keys()]
  positionsArr.shift()
  return positionsArr.map((pos) => {
    return {
      value: pos,
      label: `${pos}${getCounterSuffix(pos)}`,
    }
  })
}

export default function useLinePositionSelector(party: Partial<Party>) {
  const { locationId } = party
  const { waitTimes } = useWaitTimesByLocationId(locationId)
  const { isSubmitting, setIsSubmitting } = useWaitlistUpdates()
  const { business } = useBusiness()

  const linePositionMethod = useMemo(() => {
    return business?.linePositionMethod
  }, [business])

  const partiesCount = useMemo(() => {
    if (linePositionMethod === 'queue') {
      return waitTimes?.find((wt) => wt.queueId === party?.queueId)
        .waitingPartiesInQueue
    }
    return waitTimes?.reduce((agg, d) => {
      agg += d.waitingPartiesInQueue
      return agg
    }, 0)
  }, [party, waitTimes, linePositionMethod])

  const queueName = useMemo(() => {
    return waitTimes?.find((wt) => wt.queueId === party?.queueId)?.queueName
  }, [waitTimes])

  const linePositionOptions = useMemo(() => {
    return generateLinePositionOptions(partiesCount)
  }, [partiesCount, linePositionMethod])

  return {
    linePositionMethod,
    linePositionOptions,
    queueName,
    isSubmitting,
    setIsSubmitting,
  }
}
