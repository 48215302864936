import { styled } from "@/styles/theme"

const Button = styled("button", {
  $$buttonFgColor: "$color$white",
  color: "$$buttonFgColor",
  backgroundColor: "$$buttonBgColor",
  fontFamily: "$default",
  padding: "$3 $6",
  fontWeight: "$bold",
  borderRadius: "99px",
  cursor: "pointer",
  border: "none",
  fontSize: "16px",
  lineHeight: "16px",
  variants: {
    color: {
      green: {
        $$buttonFgColor: "$colors$white",
        $$buttonBgColor: "$colors$green",
      },
      red: {
        $$buttonFgColor: "$colors$white",
        $$buttonBgColor: "$colors$red",
      },
      blue: {
        $$buttonFgColor: "$colors$white",
        $$buttonBgColor: "$colors$blue",
      },
      midnight: {
        $$buttonFgColor: "$colors$white",
        $$buttonBgColor: "$colors$midnight",
      },
      twilight: {
        $$buttonFgColor: "$colors$white",
        $$buttonBgColor: "$colors$twilight",
      },
      gray: {
        $$buttonFgColor: "$colors$slate",
        $$buttonBgColor: "$colors$gray-light",
      },
      white: {
        $$buttonFgColor: "$colors$slate",
        $$buttonBgColor: "$colors$white",
      },
      whiteTransparent: {
        $$buttonFgColor: "$colors$white",
        $$buttonBgColor: "rgba(255, 255, 255, 0.2)",
      }
    },
    disabled: {
      true: {
        $$buttonFgColor: "$colors$gray-dark",
        $$buttonBgColor: "$colors$gray-light",
        $$buttonActiveFgColor: "$colors$gray-dark",
        $$buttonActiveBgColor: "$colors$gray-light",
        img: {
          filter: "invert(0.25)",
        },
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
  },
  compoundVariants: [
    {
      outline: true,
      color: "gray",
      css: {
        $$buttonFgColor: "$colors$slate",
      },
    },
  ],
  defaultVariants: {
    color: "green",
    outlined: false,
  },
})

export { Button }
