import { dayjs } from "@/utils/dayjs"
import { Dayjs } from "dayjs"

export const NO_WAIT_STR = "No Wait"

/**
 * Determines if the current wait time is a standardized wait time or not
 */
export const isStdWaitTime = (seconds: number): boolean =>
  getWaitTimeIntervalSecs().includes(seconds)

/**
 * Returns an array of intervals of standardized wait times in seconds
 */
export const getWaitTimeIntervalSecs = (): Array<number> => {
  let intervals = []
  for (let i = 0; i <= 90; i += 5) {
    intervals.push(i * 60)
  }
  for (let i = 105; i <= 240; i += 15) {
    intervals.push(i * 60)
  }
  return intervals
}

export const compareTimeDiffs = (
  hms1: { diff: number },
  hms2: { diff: number }
): number => hms1.diff - hms2.diff

export const getTimeElapsed = (one: Dayjs, two: Dayjs) => {
  if (!one || !two) return 0
  return dayjs.duration(one.diff(two))
}
  

export const getDisplayTime = (time: {
  h: string | number
  m: string | number
}) =>
  [time?.h && `${time?.h}h`, `${time?.m || 0}m`].filter((x) => !!x).join(" ")

export const createHMS = (
  relativeNow: Dayjs = dayjs(),
  seconds: number = 0
): { diff: number; h: number; m: number; s: number } => {
  const diffDate = relativeNow.subtract(seconds, "seconds")

  return {
    diff: relativeNow.diff(diffDate, "ms"),
    h: relativeNow.diff(diffDate, "h"),
    m: relativeNow.diff(diffDate, "m") % 60,
    s: relativeNow.diff(diffDate, "s") % 60,
  }
}



/**
 * Utils rewritten for new wait time selector
 * New intervals
 * New label string mapping
 * New best fit interval
 * @TODO - after completion and testing, remove old utils
 */

export const findNearestStdInterval = (time) => {
  const stdIntervals = getStdWaitTimeIntervals()
  if (!time) {
    return stdIntervals[0]
  }
  const matched = stdIntervals.reduce((acc, d) => {
    if (d <= time) {
      
      acc = d
    }
    return acc
  }, 0)
  return matched
}

export const getStdWaitTimeIntervals = () => {

  const intervals5mins = [...Array(24).keys()].map((item, i) => {
    // first item = 0
    if (i === 0) {
      return 0
    }
    // next 24, 5 minute increments
    if (i > 0 && i <= 24) {
      return i * 5 * 60
    }
  })

  // next 8, 15 minute increments
  let intervals15mins = []
  for (let i = 2 * 60; i <= 4 * 60; i += 15) {
    intervals15mins.push(i * 60)
  }

  // next 11, 30 minute increments
  let intervals30mins = []
  for (let i = 4 * 60; i <= 10 * 60; i += 30) {
    intervals30mins.push(i * 60)
  }

  // next 15, 1 hour increments
  let intervals1hour = []
  for (let i = 11 * 60; i <= 24 * 60; i += 60) {
    intervals1hour.push(i * 60)
  }

  // next 29, 1 day increments
  let intervals1day = []
  for (let i = 2 * 24 * 60; i <= 29 * 24 * 60; i += 24 * 60) {
    intervals1day.push(i * 60)
  }

  // next 11, 1 month increments
  let intervals1month = []
  for (let i = 30 * 24; i <= 11 * 30 * 24; i += 30 * 24) {
    intervals1month.push (i * 60 * 60)
  }

  // next 9, 1 year increment
  let intervals1year = []
  for (let i = 365 * 24; i <= 10 * 365 * 24; i += 365 * 24) {
    intervals1year.push(i * 60 * 60)
  }

  return [
    ...intervals5mins,
    ...intervals15mins,
    ...intervals30mins,
    ...intervals1hour,
    ...intervals1day,
    ...intervals1month,
    ...intervals1year,
  ]
}

export const convertSecondsToTimeStr = (
  seconds: number,
  includeNoWait: boolean = true,
): string => {
  if (!seconds && includeNoWait) {
    return NO_WAIT_STR
  }

  const oneDayInSeconds = 24 * 60 * 60
  const minutes = Math.floor(seconds / 60) % 60
  const hours = Math.floor(seconds / 3600)
  const days = Math.floor(seconds / oneDayInSeconds)
  const months = Math.floor(seconds / (oneDayInSeconds * 30))
  const years = Math.floor(seconds / (oneDayInSeconds * 365))

  if (years >= 1) {
    return `${years} year${years === 1 ? '' : 's'}`
  }
  if (months >= 1) {
    return `${months} month${months === 1 ? '' : 's'}`
  }
  if (days >= 1) {
    return `${days} day${days === 1 ? '' : 's'}`
  }
  if (hours >= 1 && hours < 10) { // don't need less than, but maybe better to read
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes > 0 ? minutes + ' minutes' : ''}`
  }
  if (hours >= 10) {
    return `${hours} hours`
  }
  
  if (hours < 1 && minutes >= 1) {
    return `${minutes} minutes`
  }
  return NO_WAIT_STR
}

export const generateWaitTimeOptions = (intervals, suggestedWaitTime?: number,) => {
  const nearestInterval = suggestedWaitTime ? findNearestStdInterval(suggestedWaitTime) : null
  const options = intervals.map(item => {
    const suggestedDecoration = item === nearestInterval ? ' (Suggested)' : ''
    return {
      label: convertSecondsToTimeStr(item) + suggestedDecoration,
      value: item,
    }
  })
  return options
}
