import { NimbleEmoji } from "emoji-mart"
import iconData from "emoji-mart/data/twitter.json"

interface Props {
  icon: string
  text?: string
}

export default function EmojiWithText({ icon, text }: Props) {
  return (
    <>
      <NimbleEmoji emoji={icon} set="twitter" data={iconData} size={20} />
      {text && <span>&nbsp;{text}</span>}
    </>
  )
}
