import SelectField from "../../fields/SelectField"
import Box from "@mui/material/Box"
import Grid from '@mui/material/Grid'
import {
  generateWaitTimeOptions,
  getStdWaitTimeIntervals,
} from "@/utils/wait-times"

const WaitTimeSelector = (props) => {
  const { label, name, notes = null, suggestedWaitTime } = props

  return (
    <Box>
      <Grid container wrap="nowrap" spacing={2} columns={2}>
        <Grid item xs={7} sm={6} md={6} lg={6} xl={6}>
          <SelectField
            label={label}
            name={name}
            notes={notes}
            options={generateWaitTimeOptions(
              getStdWaitTimeIntervals(),
              suggestedWaitTime
            )}
            style={{
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default WaitTimeSelector
