import { styled } from "@/styles/theme"

const HelperText = styled("div", {
  fontSize: "$xs",
  height: "1rem",
  variants: {
    hasError: {
      true: {
        color: "$red",
        display: "block",
      },
    },
  },
  marginBottom: 4,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

const FieldWrapper = styled("div", {
  marginBottom: "$1",
})

export { HelperText, FieldWrapper }
