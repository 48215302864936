import IconButton from "@material-ui/core/IconButton"
import { X } from "react-feather"
import { useDispatch } from "react-redux"
import { setIsBannerOpen, setBanner } from "@/store/slices/bannerSlice"
import { useTypedSelector } from "@/store/index"
import { muiTheme } from "@/styles/theme"
import styled from "@emotion/styled"
import Button from "../Button"
import Link from "next/link"
import { setDismissedBanners } from "@/store/slices/bannerSlice"
import bannerStyles from './Banner.module.scss'
import { BANNER_COLORS } from "@/consts/banner-messages"
import { useEffect } from "react"

const BannerMessage = styled('div')({
  [muiTheme.breakpoints.up('sm')]: {
    maxWidth: '75%',
  },
  [muiTheme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
})

const BannerCta = styled(Button)({
  fontSize: '14px',
  fontWeight: 600,
  [muiTheme.breakpoints.down('sm')]: {
    marginTop: '8px',
  },
})

const BannerIconButton = styled(IconButton)({
  justifySelf: 'end',
  padding: 0,
  display: 'flex',
  
  [muiTheme.breakpoints.up('sm')]: {
    position: 'absolute',
    right: '20px',
  },
  [muiTheme.breakpoints.up('lg')]: {
    position: 'absolute',
    right: '20px',
  },

  [muiTheme.breakpoints.down('sm')]: {
    position: 'absolute',
    right: '20px',
    top: '8px',
  },
})
  

const Banner = () => {
  const dispatch = useDispatch()

  const {
    bannerMessageId,
    isBannerOpen,
    bannerKeepOpen,
    bannerType,
    bannerMessage,
    bannerLink,
    bannerLinkText,
  } = useTypedSelector((state) => state.banner)

  return (
    <>
      {isBannerOpen && (
        <div 
          className={`${bannerStyles.banner} flex justify-center items-center`}
          style={{ 
            fontFamily: "Montserrat", 
            fontWeight: 500, 
            whiteSpace: "pre-wrap",
            backgroundColor: BANNER_COLORS[bannerType],
            color: 'white',
          }}
        >
          <BannerMessage>{bannerMessage}</BannerMessage>
          {bannerLink && (
            <Link href={bannerLink}>
              <BannerCta color="whiteTransparent">{bannerLinkText}</BannerCta>
            </Link>
          )}
          <BannerIconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={() => {
              dispatch(setIsBannerOpen(false))
              dispatch(setDismissedBanners(bannerMessageId))
            }}
          >
            <X />
          </BannerIconButton>
        </div>
      )}
    </>
  )
}

export default Banner
