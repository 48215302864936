import useLocations from "@/hooks/api-hooks/useLocations"
import { useTypedSelector } from "@/store/index"
import { getSelectedObjectById } from "@/utils/util-functions"
import { useMemo } from "react"

export default function useSelectedLocation() {
  const { locationId } = useTypedSelector((state) => state.selected)

  const { locations, mutateLocations } = useLocations()

  return useMemo(
    () => ({
      selectedLocation: getSelectedObjectById(locationId, locations),
      mutateLocations,
    }),
    [locationId, locations]
  )
}
