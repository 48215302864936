import axios from "axios"
import { Address } from "../interface/address"

export const getAddresses = (params = {}) =>
  axios.get(`v2/addresses`, { params }).then((res) => res.data.data)

export const getAddress = (addressId: Address["id"], params = {}) =>
  axios
    .get(`v2/addresses/${addressId}`, { params })
    .then((res) => res.data.data)

export const updateAddress = (address: Partial<Address>) =>
  axios
    .patch(`v2/addresses/${address.id}`, address)
    .then((res) => res.data.data)

export const createAddress = (address: Partial<Address>) =>
  axios.post(`v2/addresses`, address).then((res) => res.data.data)
