// https://www.browserstack.com/guide/responsive-design-breakpoints
/*
Notes:
One of the problems with how we're using media queries in this app is, they're
being used in a lot of places that would benefit from just having flexbox instead.
If it's causing undue stress on the app's performance by calling these hooks in
various places, let's take a day and refactor into flexbox. Also, there probably
shouldn't be functionality in the actual app that depends on the screen being
a specific size, but rather just using these breakpoints to display different
visual variants that allow for the same functionality.
*/
import { useMediaQuery } from "react-responsive"

//* NEW
export const usePhoneOnlyQuery = () => useMediaQuery({ maxWidth: 599 })
export const useTabletPortraitUpQuery = () => useMediaQuery({ minWidth: 600 })
export const useTabletLandscapeUpQuery = () => useMediaQuery({ minWidth: 900 })
export const useDesktopUpQuery = () => useMediaQuery({ minWidth: 1200 })
export const useBigDesktopUpQuery = () => useMediaQuery({ minWidth: 1800 })
export const useTabletPortraitOnly = () => useMediaQuery({ minWidth: 600, maxWidth: 1040 })

// custom breakpoints
export const useDesktop1280UpQuery = () => useMediaQuery({ minWidth: 1280 })

//* OLD
export const useSmallMobileQuery = () => useMediaQuery({ maxWidth: 600 })
export const useMobileQuery = () => useMediaQuery({ maxWidth: 768 })
export const useMobileOrTabletQuery = () => useMediaQuery({ maxWidth: 1024 })
