import usePartiesByLocation from '@/hooks/api-hooks/usePartiesByLocation'
import { useEffect, useMemo, useState } from 'react'
import useSelectedQueue from '@/hooks/useSelectedQueue'
import { useTypedSelector } from '@/store/index'
import useSelectedLocation from '@/hooks/api-selected-hooks/useSelectedLocation'
// these are commented out below. uncomment if using.
// import {
//   filterPartiesByCFValues,
//   filterPartiesBySearchTerm,
// } from '@/utils/parties-utils'

/**
 * This is now almost totally superfluous because all filtering
 * is being done on the server which makes pagination work correctly.
 * Left in because it is a dependency in many places,
 * but is the same as usePartiesByLocation now except for the extra
 * layer of business queue filtering just in case and just returning other stuff.
 */

export default function useFilteredParties() {
  const {
    filters: { isHistory, status, customFields },
    searchTerm,
  } = useTypedSelector((state) => state.pages.waitlist)
  const { businessId, queueId, locationId } = useTypedSelector(
    (state) => state.selected
  )
  const { filters } = useTypedSelector((state) => state.pages.waitlist)
  const { selectedLocation } = useSelectedLocation()

  const { parties, partiesMeta, mutateParties, isValidating } =
    usePartiesByLocation(isHistory)

  // do we still need this? we already have the id from the store
  // get selected queue for filtering
  const { selectedQueue } = useSelectedQueue(
    selectedLocation?.enabledQueues || []
  )
  // Filter parties based on waitlist filters
  const [filteredParties, setFilteredParties] = useState([])

  // filter those parties!
  useEffect(() => {
    if (isValidating) return
    // Filter parties by business id
    const filtered = parties.filter((party) => {
      return party.businessId === businessId
    })
    // both CF and Search filters will result in incorrect pagination
    // if only done on the FE. filtering must be done on the api request
    // for total counts and pages to be correct. abstracted to utils for easy on/off.
    // .filter(filterPartiesByCFValues, customFields)
    // .filter(filterPartiesBySearchTerm, [searchTerm, selectedQueue])

    // if the user has selected all queues, just show all parties
    if (selectedQueue?.id === null) {
      setFilteredParties(filtered)
      return
    }

    // finally, filter by the queue
    const partiesByQueue = filtered.filter(
      (p) => p.queueId === selectedQueue?.id
    )

    setFilteredParties(partiesByQueue)
  }, [
    isHistory,
    parties,
    searchTerm,
    selectedQueue,
    status,
    customFields,
    isValidating,
    filters,
  ])

  return {
    parties: filteredParties,
    responseMeta: partiesMeta,
    mutateParties,
    isValidating,
    totalParties: filteredParties.length,
  }
}
