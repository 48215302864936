import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import badgeStyles from "@/styles/Badge.module.scss"
import useActiveSubscription from "@/hooks/useActiveSubscription";
import Link from "next/link";
import { PLAN_BADGES } from "@/consts/Subscriptions";
import usePermissions from "@/hooks/usePermissions";

const PlanBadge = () => {
    const { subscription: { type } } = useActiveSubscription()
    const { hasPermissions } = usePermissions('update location address')

    return (
        <div 
            className={classNames(badgeStyles.badge, badgeStyles.plan, badgeStyles[PLAN_BADGES[type].color])}
            style={{ marginRight: 0 }}
        >   
            {hasPermissions ? (
                <Link
                    href={`/business/subscription`}
                >
                    <a 
                        className="text-white"
                        style={{ 
                            fontSize: '14px', 
                            fontWeight: 600 
                        }}
                    >
                        {PLAN_BADGES[type].text}
                    </a>
                </Link>
            ) : (
                <span
                    className="text-white"
                    style={{ 
                        fontSize: '14px', 
                        fontWeight: 600,
                    }}
                >
                    {PLAN_BADGES[type].text}
                </span>
            )}
        </div>
    )
}

export default PlanBadge