import axios from "axios"
import { Guest } from "../interface/guest"

export const getGuests = (params = {}) =>
  axios.get(`v2/guests`, { params }).then((res) => res.data.data)

export const getGuestByPhoneNumber = (phoneNumber: string) =>
  axios
    .get(`v2/guests`, {
      params: {
        filter: { phone: phoneNumber },
        include: ["lastVisit"],
      },
    })
    .then((res) => res.data.data)

export const getGuest = (guestId: Guest["id"]) =>
  axios.get(`v2/guests/${guestId}`).then((res) => res.data.data)

export const createGuest = (guest: Partial<Guest>) =>
  axios.post(`v2/guests`, guest).then((res) => res.data.data)

export const updateGuest = (guest: Partial<Guest>) =>
  axios.patch(`v2/guests/${guest.id}`, guest).then((res) => res.data.data)
