import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-ca'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'

const useStyles = makeStyles({
  root: {
    width: '100%',
    ['& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl .MuiInputBase-input.MuiOutlinedInput-input']:
      {
        paddingTop: '12px',
        paddingBottom: '12px',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      },
  },
  wrapper: {
    marginBottom: '20px',
  },
  label: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    letterSpacing: 'var(--letterSpacings-3)',
    fontSize: '14px',
    fontWeight: 500,
    display: 'block',
    marginBottom: '4px',
  },
})

export interface DatePickerInputProps {
  label?: string
  name: string
  required?: boolean
  icon?: string
  value?: string
  isFieldTouched?: boolean
  parentError?: boolean
  handleChange: Function
  setParentError?: Function
  setFieldTouched?: Function
}

const DatePickerInput = ({
  label,
  name,
  required,
  icon,
  value,
  isFieldTouched,
  parentError,
  handleChange,
  setParentError,
  setFieldTouched,
}: DatePickerInputProps) => {
  const classes = useStyles()
  const [error, setError] = useState('')

  const formattedValue = useMemo(() => {
    if (dayjs(value).isValid()) {
      return dayjs(value).format('YYYY-MM-DD')
    }
    return dayjs().format('YYYY-MM-DD')
  }, [value])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={classes.wrapper}>
        <label className={classes.label}>{label}</label>
        <DatePicker
          className={classes.root}
          value={formattedValue}
          onChange={(newVal) => {
            setFieldTouched && setFieldTouched(name, true)
            if (newVal && dayjs(newVal).isValid()) {
              const formattedValue =
                typeof newVal !== 'string'
                  ? // @ts-ignore
                    newVal.format('YYYY-MM-DD')
                  : newVal
              handleChange(formattedValue, name)
              setError('')
              setParentError(name, undefined)
            } else {
              const error = !newVal ? 'Required' : 'Invalid date'
              setError(error)
              if (setParentError) {
                setParentError(name, error)
              }
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                name={name}
                {...params}
                error={!!error}
                onBlur={() =>
                  !!error ? setParentError(error) : setParentError(undefined)
                }
                helperText={!!error || !!parentError ? error : null}
                FormHelperTextProps={{
                  style: {
                    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
                    marginLeft: 0,
                  },
                }}
              />
            )
          }}
        />
      </div>
    </LocalizationProvider>
  )
}

export default DatePickerInput
