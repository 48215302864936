/* eslint-disable react/display-name */
import React from 'react'
import Label from '@/components/common/forms/Label'
import { styled } from '@/styles/theme'
import { forwardRef } from 'react'

export const StyledInput = styled('input', {
  color: '$slate',
  height: '$10',
  fontSize: '$body',
  paddingLeft: '$4',
  paddingRight: '$4',
  paddingTop: '$1',
  marginBottom: '$1',
  lineHeight: '$1',
  appearance: 'none',
  backgroundColor: '$white',
  border: '$sizes$px solid $colors$border',
  borderRadius: '$1',
  fontFamily: '$default',
  position: 'relative',
  display: 'block',
  width: 'auto',
  '&:active, &:focus': {
    border: '2px solid $colors$blue',
    outline: 'none',
  },
  '&:disabled, &.disabled': {
    backgroundColor: '$gray-light',
    cursor: 'not-allowed',
  },
  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    hasIcon: {
      true: {
        paddingLeft: '$8',
      },
    },
  },
})

interface InputProps {
  id?: string
  label?: string
  type?: string
  value?: any
  required?: boolean
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  autoComplete?: string
}

const Input = forwardRef(
  ({ id, label, type, required, ...rest }: InputProps, ref) => {
    //   const { id, label, type, value, ...rest } = props

    const fieldId = id || label?.toLowerCase().replace(' ', '_')

    return (
      <>
        {label && (
          <Label htmlFor={fieldId}>{`${label}${required ? ' *' : ''}`}</Label>
        )}
        {type === 'tel' || type === 'number' ? (
          <StyledInput
            id={fieldId}
            {...rest}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
          />
        ) : (
          <StyledInput id={fieldId} type={type || 'text'} {...rest} />
        )}
      </>
    )
  }
)

Input.defaultProps = {
  label: '',
  fullWidth: true,
}

export { Input }
