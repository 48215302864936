import { useEffect, useState } from "react";
import useAccount from "./api-hooks/useAccount";
import useBusiness from "./api-hooks/useBusiness";
import { useDispatch } from "react-redux";
import { setUserData } from "@/store/slices/userSlice";
import { useTypedSelector } from "../store";
import useIndustry from "./api-hooks/useIndustry";
import dayjs from "dayjs";
import { getDefaultCountryFromBizPhone } from "@/utils/phone-utils";

export default function useStoreUserData() {
  const dispatch = useDispatch()
  const { account, isValidating: isAccountValidating } = useAccount()
  const { business, isValidating: isBusinessValidating } = useBusiness()
  const { industry } = useIndustry(business?.industryId)

  const user = useTypedSelector(state => state.user)

  const storeUserData = () => {
    const { currentUser, currentBusiness} = user

    // if userId || businessName have changed set user data
    if (
      account && 
      business && 
      industry && 
      (account?.id !== currentUser?.id || business?.name !== currentBusiness.name || currentBusiness.industry !== industry?.name)
    ) {
      const {
        id,
        businessId,
        firstName,
        lastName,
        email,
        status,
        createdAt,
        roles,
        permissions,
        phone,
      } = account

      const {
        name,
        phone: businessPhone,
        status: businessStatus,
        trialEndsAt,
        currentSubscriptionPeriod,
      } = business

      const {
        name: industryName
      } = industry

      dispatch(setUserData({ 
        currentUser: {
          id,
          businessId,
          firstName,
          lastName,
          email,
          status,
          createdAt,
          roles,
          permissions,
          phone,
        }, 
        currentBusiness: {
          name,
          phone: businessPhone,
          status: businessStatus,
          locationCount: currentSubscriptionPeriod?.locationCount,
          industry: industryName,
          planType: currentSubscriptionPeriod?.type,
          trialEndsAt: dayjs(trialEndsAt).format('MMM DD, YYYY'),
          defaultCountryISO: getDefaultCountryFromBizPhone(businessPhone) || business.address.country,
        },
      }))
    }
  }

  useEffect(() => {
    storeUserData()
  }, [account, business, industry])
}