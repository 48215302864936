import classNames from 'classnames'
import styles from '@/styles/Waitlist.module.scss'
import Colors from '@/styles/Colors.module.scss'
import {
  getDisplayTimeDuration,
  filterUnits,
  getJoinedAtDisplayTime,
} from '@/utils/time-utils'
import { getTimeElapsed } from '@/utils/wait-times'
import { LogIn } from 'react-feather'
import { Party } from '@/api/interface/party'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { cleanTimezone, unformattedTimeDiff } from '@/utils/time-utils'

export interface WaitlistEntryWaitTimeProps {
  party: Party
  isHistory: boolean
  updatedDiff: any
  guestWaitTimesValues: any
  timezone?: string
}
const WaitlistEntryWaitTime = ({
  party,
  isHistory,
  updatedDiff,
  guestWaitTimesValues,
  timezone = 'America/Los_Angeles',
}) => {
  const { isBooking, bookingTime, bookingTimeLocal } = party

  const shouldDisplayBookingTime = useMemo(() => {
    return isBooking && bookingTime
  }, [isBooking, bookingTime])

  const cleanedBookingTime = useMemo(() => {
    if (bookingTimeLocal) {
      return cleanTimezone(bookingTimeLocal)
    }
    return cleanTimezone(bookingTime)
  }, [bookingTime])

  const formattedBookingTime = useMemo(() => {
    return isBooking && cleanedBookingTime
      ? dayjs(cleanedBookingTime).format('h:mma')
      : null
  }, [isBooking, cleanedBookingTime])

  const formattedBookingDate = useMemo(() => {
    if (isBooking && cleanedBookingTime) {
      const dateObj = dayjs(cleanedBookingTime)
      const today = dayjs()
      const weekday =
        dateObj.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
          ? 'Today'
          : dateObj.format('dddd')

      return `${weekday}, ${dateObj.format('M/D/YYYY')}`
    }
    return null
  }, [isBooking, cleanedBookingTime])

  const formattedBookingElapsedWaitTime = useMemo(() => {
    if (cleanedBookingTime) {
      const unformattedDiff = unformattedTimeDiff(cleanedBookingTime, dayjs())
      if (unformattedDiff >= 0) {
        return ''
      }
      const bookingElapsedTime = getTimeElapsed(
        dayjs(cleanedBookingTime),
        dayjs()
      )

      return filterUnits(
        getDisplayTimeDuration(
          // @ts-ignore
          bookingElapsedTime.asMilliseconds(),
          'milliseconds',
          2
        ),
        ['s']
      )
    }
    return '0m'
  }, [cleanedBookingTime])

  const isNowPastBookingTime = useMemo(() => {
    //
  }, [cleanedBookingTime])

  const formattedElapsedWaitTime = useMemo(() => {
    return guestWaitTimesValues?.value1?.diff
      ? filterUnits(
          getDisplayTimeDuration(
            guestWaitTimesValues.value1.diff,
            'milliseconds',
            2
          ),
          ['s']
        )
      : '0m'
  }, [guestWaitTimesValues])

  const formattedEstimatedWaitTime = useMemo(() => {
    return guestWaitTimesValues?.value2?.diff
      ? filterUnits(
          getDisplayTimeDuration(
            guestWaitTimesValues.value2.diff,
            'milliseconds',
            2
          ),
          ['s']
        )
      : '0m'
  }, [guestWaitTimesValues])

  const isBookingWaitTimeNegative = useMemo(() => {
    const bookingElapsedTime = cleanedBookingTime
      ? getTimeElapsed(dayjs(cleanedBookingTime), dayjs())
      : 0
    return Boolean(
      bookingElapsedTime !== 0 && bookingElapsedTime.asMilliseconds() < 0
    )
  }, [cleanedBookingTime])

  const formattedInServiceWaitTimeHistory = useMemo(() => {
    return !!updatedDiff
      ? getDisplayTimeDuration(updatedDiff.asMilliseconds(), 'milliseconds', 1)
      : '0m'
  }, [updatedDiff])

  return (
    <>
      <p
        id="waitlist-entry-wait-time"
        className={classNames(styles.entryWaitTime)}
      >
        {isHistory ? (
          <>
            {party?.status === 'in_service' && (
              <span
                className={
                  updatedDiff?.milliseconds() > 1800000 ? Colors.red : null
                }
              >
                {formattedInServiceWaitTimeHistory}
                <span style={{ fontWeight: 500 }}> Service Time</span>
              </span>
            )}
          </>
        ) : (
          <>
            <span
              className={guestWaitTimesValues.hasElapsed ? Colors.red : null}
            >
              {shouldDisplayBookingTime ? (
                <>
                  <span>
                    {party?.status === 'waiting' &&
                    formattedBookingElapsedWaitTime ? (
                      <>
                        <span
                          className={
                            isBookingWaitTimeNegative ? Colors.red : null
                          }
                        >
                          {formattedBookingElapsedWaitTime}
                        </span>{' '}
                        <span>/</span> <span>{formattedBookingTime}</span>
                      </>
                    ) : (
                      <span>{formattedBookingTime}</span>
                    )}
                  </span>
                </>
              ) : (
                <>{formattedElapsedWaitTime}</>
              )}
            </span>
            {!shouldDisplayBookingTime && (
              <>
                <span> / </span>
                <span>{formattedEstimatedWaitTime}</span>
              </>
            )}
          </>
        )}
      </p>
      <span
        id="waitlist-entry-join-span"
        className={classNames(styles.entryJoinSpan)}
      >
        {party.source === 'self-check-in' && (
          <p
            id="waitlist-entry-join-source"
            className={classNames(styles.entryJoinSource)}
          >
            <LogIn />
          </p>
        )}
        <p
          id="waitlist-entry-join-time"
          className={classNames(styles.entryJoinTime)}
        >
          {shouldDisplayBookingTime ? (
            <>{formattedBookingDate}</>
          ) : (
            <>
              {party.source === 'self-check-in' ? 'Joined ' : 'Added '}
              {getJoinedAtDisplayTime(party.createdAtDate, timezone)}
            </>
          )}
        </p>
      </span>
    </>
  )
}

export default WaitlistEntryWaitTime
