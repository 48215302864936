import useSWR from "swr"
import { refreshIntervals } from "@/consts/swr"
import { getQueues } from "@/api/nextme/queues"

export default function useQueues() {
  const {
    data: queues,
    isValidating,
    mutate: mutateQueues,
    error,
  } = useSWR("/nextme/queues/", () => getQueues(), {
    fallbackData: [],
    refreshInterval: refreshIntervals.verySlow,
    dedupingInterval: 10000, // 10s
    revalidateOnFocus: false,
    refreshWhenHidden: true,
  })

  return {
    isValidating,
    queues,
    mutateQueues,
    error,
  }
}
