import { Account } from "@/api/interface/account"
import { UserRole, UserRolesOrdered } from "@/consts/UserRolesEnum"

//* Check if user has sufficient permission based on index of role
export const hasMinimumRole = (
  minRole: UserRole,
  userRole: UserRole = "Guest (Public)"
) => {
  const roles = UserRolesOrdered.slice().reverse()

  return (
    userRole && minRole && roles.indexOf(userRole) >= roles.indexOf(minRole)
  )
}

export const hasSpecificPermission = (permission: string, account: Account) => {
  //TODO
  return
}
