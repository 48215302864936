/* eslint-disable react/display-name */
import { useField } from "formik"
import Input from "@/components/common/forms/Input"
import { HelperText } from "@/components/common/forms/Common"
import { ChangeEventHandler, forwardRef } from "react"
import classNames from "classnames"
import styles from "@/styles/Forms.module.scss"

interface Props {
  [key: string]: any
  id?: string
  label?: string
  notes?: string
  error?: string
  fullWidth?: boolean
  hideError?: boolean
  hideErrorEmpty?: boolean
  min?: number
  max?: number
  step?: number
  required?: boolean
  type?: string
  autoComplete?: string
  disabled?: boolean
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  style?: object
  className?: string
  notesStyle?: any
}

const InputField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    style,
    notes,
    hideError,
    hideErrorEmpty,
    notesStyles,
    ...rest
  } = props
  const [field, meta] = useField(rest)

  let error = "Required"

  if (field?.value?.length > 0) {
    if (meta.touched && meta.error) {
      error = meta.error
    } else {
      error = ""
    }
  } else {
    error = meta.touched ? meta.error : ""
  }

  error = rest.error || error

  return (
    <div className={classNames(styles.inputField, className)} style={style}>
      <Input
        className={classNames(error && styles.error, className)}
        {...field}
        {...rest}
        ref={ref}
      />
      {notes && <HelperText style={notesStyles}>{notes}</HelperText>}
      {hideError
        ? null
        : !(hideErrorEmpty && (!error || error === " ")) && (
            <HelperText hasError={true}>{error}</HelperText>
          )}
    </div>
  )
})

export default InputField
