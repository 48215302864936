import { components } from "react-select"
import { styled, theme as appTheme } from "@/styles/theme"
import EmojiWithText from "@/components/common/EmojiWithText"

export const ReactSelectCustomStyles = {
  control: (provided) => ({
    ...provided,
    marginBottom: "0.5rem",
  }),
  option: (provided) => ({
    ...provided,
    color: appTheme.colors.slate,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    height: "30px",
    lineHeight: "30px",
    // paddingLeft: "3px",
    // paddingRight: "3px",
    marginLeft: -8,
    marginRight: 12,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "var(--space-4)",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    paddingLeft: "10px",
    paddingRight: "10px",
  }),
}

export const ReactSelectCustomTheme = (theme) => ({
  ...theme,
  borderWidth: "2px",
  colors: {
    ...theme.colors,
    primary: appTheme.colors.green,
  },
})

export const ReactSelectDropdownIndicator = styled(
  components.DropdownIndicator,
  {
    height: "$10",
    backgroundColor: "$white",
    background: "url(/icons/caret-down.svg) right 0.5rem center no-repeat",
    svg: {
      visibility: "hidden",
    },
  }
)

export const ReactSelectOptionWithEmoji = (props) => (
  <components.Option {...props}>
    <EmojiWithText text={props.children} icon={props?.data?.icon} />
  </components.Option>
)

export const ReactSelectSingleValue = (props) => {
  return props?.data?.icon ? (
    <components.SingleValue {...props}>
      <EmojiWithText text={props.children} icon={props?.data?.data?.icon} />
    </components.SingleValue>
  ) : (
    <components.SingleValue {...props}>{props.children}</components.SingleValue>
  )
}

//TODO: if inaccessible (e.g. biz-level custom field on a loc-level queue) show in a disabled state
//TODO: this shows up on the edit queue form
export const ReactSelectMultiValueOption = ({
  children,
  isSelected,
  data,
  ...rest
}) => {
  return isSelected || data.impossible ? null : (
    <components.Option {...rest}>
      <div className="flex items-center">
        {data?.icon ? (
          <EmojiWithText icon={data.icon} />
        ) : (
          <img src={`/icons/checked-empty.svg`} alt="" />
        )}
        {children}
      </div>
    </components.Option>
  )
}

export const ReactSelectMultiValueLabel = (props) => {
  return props?.data?.icon ? (
    <components.MultiValueLabel {...props}>
      <EmojiWithText text={props.children} icon={props?.data?.icon} />
    </components.MultiValueLabel>
  ) : (
    <components.MultiValueLabel {...props}>
      {props.children}
    </components.MultiValueLabel>
  )
}

export const ReactSelectMultiValueRemove = (props) => {
  if (props.data.impossible) {
    return null
  }
  return <components.MultiValueRemove {...props} />
}
