import { tz } from "moment-timezone"

/**
 * Transform an object array into Select options.
 * @param options {Array<Object|String>} - and array of objects of potential dropdown items of any object.
 * @param valueKey {String} the key to map to the option value
 * @param labelKey {String} the key to map to the option label
 */
export const optionsFromKeys = (
  options = [],
  valueKey = null,
  labelKey = null
) => {
  const hasArrLength = options?.length > 0
  if (!hasArrLength) return []
  return options?.map((option) => {
    let value, label

    if (typeof option === "string") {
      value = option
      label = option
    } else {
      // assuming an options is an array of objects.
      if (option[valueKey] === "undefined") {
        console.error(
          `valueKey: ${valueKey} not found in ${JSON.stringify(
            option,
            null,
            2
          )}`
        )
      }
      if (option[labelKey] === "undefined") {
        console.error(
          `labelKey: ${labelKey} not found in ${JSON.stringify(
            option,
            null,
            2
          )}`
        )
      }

      value = option[valueKey]
      label = option[labelKey]
    }

    return {
      value,
      label,
    }
  })
}

/**
 * Comparator for sorting an array of strings ending in numbers
 * ['EXHIbit 10', 'exhibit 3'] and sort them in a way that makes sense for
 * human readability ['exhibit 3', 'EXHIbit 10']
 * See also: https://blog.codinghorror.com/sorting-for-humans-natural-sort-order/
 * @param {string} key (optional) if sorting an array of objects, the object key to sort by
 * @returns comparator function for use in Array.prototype.sort()
 */
export const compareArrayNatural =
  (key = null) =>
  (prev, curr) => {
    const a = key ? prev[key] : prev
    const b = key ? curr[key] : curr

    const regex = /(.+\b)(\d+$)/
    const aMatch = a.match(regex)
    const bMatch = b.match(regex)

    const stringCompare = (x, y) => {
      return x < y ? -1 : x > y ? 1 : 0
    }

    if (!aMatch || !bMatch) {
      return stringCompare(a, b)
    }

    const { 1: aText, 2: aNum } = aMatch
    const { 1: bText, 2: bNum } = bMatch

    return (
      (aText === bText && aNum - bNum) ||
      stringCompare(aText.toLowerCase(), bText.toLowerCase())
    )
  }

export const hidePhoneNumber = (str) => {
  return str.replace(/(\d\d\d)-/g, "XXX-")
}

export const queueIdToName = (queueId, queues) => {
  return (
    queues && (queues.find((eq) => eq.id === queueId)?.name || queues[0].name)
  )
}

/**
 *
 * @param {number} start starting number in range
 * @param {number} end ending number in range
 * @returns {number[]} array of numbers from start to end (inclusive)
 */
export const range = (start, end) => {
  if (typeof start === "string") start = parseInt(start, 10)
  if (typeof end === "string") end = parseInt(end, 10)
  if (isNaN(start) || isNaN(end)) return []

  return [...Array(end - start + 1).keys()].map((x) => x + start)
}

export const randomString = (numChars = 6) =>
  (Math.random() + 1).toString(36).substring(2, numChars + 2)

export const checkPossibleArrayValue = (val) =>
  Array.isArray(val) ? val?.length > 0 : !!val

export const createDownloadLink = (filename, fileData, format) => {
  const link = document.createElement("a")

  switch (format) {
    case "xlsx":
      link.href = window.URL.createObjectURL(
        new Blob([fileData], {
          type: "application/vnd:ms-excel",
        })
      )
      break
    case "csv":
      link.href = `data:text/csv,${encodeURIComponent(fileData)}`
      break
  }

  link.download = filename

  return link
}

export const displayTimeZones = (countryAbbreviation = "US") =>
  tz
    .zonesForCountry(countryAbbreviation, true)
    .sort((a, b) => a.offset - b.offset)
    .map((x) => {
      //* e.g. 240 -> "GMT-04:00"
      const offsetHours = x.offset / 60
      const parsedOffset = `GMT-${offsetHours < 10 ? "0" : ""}${offsetHours}:00`

      //* e.g. "America/North_Dakota/New_Salem" -> "North Dakota (New Salem)"
      const parsedText = x.name
        .split("/")
        .slice(1)
        .map((y, i) => (i > 0 ? `(${y})` : y))
        .join(" ")
        .replace(/_/g, " ")

      const label = `(${parsedOffset}) ${parsedText}`

      return {
        ...x,
        parsedText,
        parsedOffset,
        label,
      }
    })
