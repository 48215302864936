import { useMemo, useEffect } from 'react';
import useCustomFields from "./api-hooks/useCustomFields";
import { getQueuesWithDynamicField, parseCfSettings } from "@/utils/custom-field-utils";
import { useTypedSelector } from '../store';
import useQueues from './api-hooks/useQueues';

export default function useDynamicCF() {
  const { locationId } = useTypedSelector((state) => state.selected)
  const { customFields } = useCustomFields()
  const { queues } = useQueues()

  const parsedCustomFields = useMemo(() => {
    return customFields?.map((cf) => {
      return {
        ...cf,
        settings: parseCfSettings(cf.settings),
      }
    })
  }, [customFields])

  const dynamicCustomFields = useMemo(() => {
    return parsedCustomFields?.filter((cf) => {
      return cf.settings?.isDynamic
    })
  }, [parsedCustomFields])


  const queueIds = useMemo(() => {
    return queues?.filter((q) => q.locations?.map((l) => l.id)?.includes(locationId)).map((q) => q.id)
  }, [queues])

  const queuesWithDynamicFields = useMemo(() => {
    return getQueuesWithDynamicField(customFields, queueIds)
  }, [customFields, queueIds])

  return {
    queuesWithDynamicFields,
    dynamicCustomFields,
  }
}