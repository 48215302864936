import badgeStyles from '@/styles/Badge.module.scss'

export interface CountBadgeProps {
  count: number | string
  position?: {
    top?: string
    right?: string
    bottom?: string
    left?: string
  }
}

export default function CountBadge({ count, position }: CountBadgeProps) {
  return (
    <>
      {typeof count === 'number' && (
        <>
          <div className={badgeStyles.countBadge} style={{ ...position }}>
            <span>{count}</span>
          </div>
        </>
      )}
    </>
  )
}
