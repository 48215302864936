import { refreshIntervals } from "@/consts/swr"
import { getIndustries } from "@/api/nextme/industries"
import useSWR from "swr"

export default function useIndustries() {
  const {
    data: industries,
    error,
    isValidating,
    mutate,
  } = useSWR(`/nextme/industries`, () => getIndustries(), {
    dedupingInterval: 10000,
    fallbackData: [],
    refreshInterval: refreshIntervals.verySlow,
    revalidateOnFocus: false,
  })

  return {
    error,
    isValidating,
    industries,
    mutateIndustries: mutate,
  }
}
