import useSWR from "swr"
import { useTypedSelector } from "@/store/index"
import { getCustomFieldsByLocationId } from "@/api/nextme/custom-fields"
import { refreshIntervals } from "@/consts/swr"

export default function useCustomFieldsByLocationId() {
  const { locationId } = useTypedSelector((state) => state.selected)

  const {
    data: customFields,
    isValidating,
    mutate,
    error,
  } = useSWR(
    `nextme/customFields-locationId-${locationId}`,
    () => getCustomFieldsByLocationId(locationId),
    {
      dedupingInterval: 10000, // 10s
      refreshInterval: refreshIntervals.verySlow,
      fallbackData: [],
      //   revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    error,
    isValidating,
    mutate,
    customFields,
  }
}
