import useSWR from "swr"
import { refreshIntervals } from "@/consts/swr"
import { getNotificationSettings } from "@/api/nextme/notification-settings"
import { NotificationSettings } from "@/api/interface/notification-settings"

export default function useNotificationSettings(params?: object) {
  const {
    data: notificationSettings,
    error,
    isValidating,
    mutate,
  } = useSWR(
    "nextme/notification-settings",
    () => getNotificationSettings<NotificationSettings>(params),
    {
      dedupingInterval: 10000,
      fallbackData: [],
      refreshInterval: refreshIntervals.verySlow,
      revalidateOnFocus: false,
    }
  )

  //TODO: add this into selectedSlice and set selected at DashboardLayout
  return {
    error,
    isValidating,
    notificationSettings,
    mutateNotificationSettings: mutate,
  }
}
