export const getCounterSuffix = (number: number) => {
  const numStr = number.toString()
  const lastDigit = parseInt(numStr.charAt(numStr.length - 1))
  const last2Digits = parseInt(numStr.substring(numStr.length - 2, 2))

  if (last2Digits === 11 || last2Digits === 12 || last2Digits === 13) {
    return 'th'
  }

  switch (lastDigit) {
    case 1:
      return `st`
    case 2:
      return `nd`
    case 3:
      return `rd`
    default:
      return `th`
  }
}
