import useSWR from 'swr'
import { useSWRConfig } from 'swr'
import { getPartiesByLocation } from '@/api/nextme/parties'
import { refreshIntervals } from '@/consts/swr'
import { useTypedSelector } from '@/store/index'
import { useCallback, useMemo } from 'react'
import { Location } from '@/api/interface/location'
import { PartyApiParams } from '@/api/interface/party'
import { convertPartyCreatedAt } from '@/utils/time-utils'
import useTimezone from '../useTimezone'

export default function usePartiesByLocation(
  useHistorical?: boolean,
  locationIdOverride?: Location['id']
) {
  const { mutate } = useSWRConfig()
  const timezone = useTimezone()

  const {
    locationId: selectedLocationId,
    businessId,
    queueId,
  } = useTypedSelector((state) => state.selected)
  const {
    partiesParams,
    activePartiesParams,
    historicalPartiesParams,
    filters,
  } = useTypedSelector((state) => state.pages.waitlist)
  const locationId = useMemo(() => {
    return locationIdOverride ?? selectedLocationId
  }, [selectedLocationId, locationIdOverride])

  // build party api params more explicitly for clarity
  const shouldUseHistory = useMemo(() => {
    // backwards compatible for useHistorical boolean
    if (useHistorical != null) {
      return useHistorical
    }
    return filters.isHistory
  }, [filters, useHistorical])

  const newStatusList = useMemo(() => {
    // backwards compatible for useHistorical boolean
    // test filters to see if we need this
  }, [filters, historicalPartiesParams, activePartiesParams, shouldUseHistory])

  const newFilter = {
    ...partiesParams?.filters,
    ...filters,
    onlyShow: filters?.isBooking ? 'all' : 'active',
    s: partiesParams?.filter?.s,
    isHistory: shouldUseHistory,
    customFields: filters?.customFields ? filters.customFields : null,
  }

  const sortFieldValue = useMemo(() => {
    if (newFilter?.isHistory) {
      return '-parties.order_column'
    }
    if (newFilter?.isBooking) {
      return 'parties.booking_time'
    }
    return 'parties.order_column'
  }, [newFilter])

  const newPartiesParams = useMemo(
    () => ({
      filter: newFilter,
      page: partiesParams?.page,
      sort: sortFieldValue,
      includes: partiesParams?.includes,
    }),
    [newFilter, partiesParams]
  )

  const key = useMemo(
    () => ({
      path: `/nextme/partiesByLocation_locationId=${locationId}_historical=${useHistorical}_queueId=${queueId}`,
      params: newPartiesParams,
    }),
    [newPartiesParams]
  )

  const {
    data: { data, meta },
    isLoading,
    isValidating,
    mutate: mutateParties,
    error,
  } = useSWR(
    {
      key,
      params: newPartiesParams,
    },
    (key) => {
      if (locationId) {
        const { params } = key

        return getPartiesByLocation(locationId, businessId, queueId, params)
      }
      return null
    },
    {
      fallbackData: { data: [], meta: null },
      refreshInterval: refreshIntervals.quicker,
      revalidateOnFocus: false,
      refreshWhenHidden: true,
    }
  )

  const dataTzConverted = useMemo(() => {
    return convertPartyCreatedAt(data, timezone)
  }, [data, timezone, filters])

  // Can use this to force cache update in cases where there's
  // a race condition between redux store update and SWR mutate
  // because we can pass param overrides directly to the global mutate function.
  // Getting the params from the key above seems to clear up all race conditions for now.
  const mutatePartiesCustom = useCallback(
    (overridePartiesParams: Partial<PartyApiParams>) => {
      const filter = {
        ...newPartiesParams.filter,
        ...overridePartiesParams.filter,
      }
      const page = {
        ...newPartiesParams.page,
        size: overridePartiesParams?.page?.size
          ? overridePartiesParams.page.size
          : newPartiesParams.page.size,
        number: overridePartiesParams?.page?.number
          ? overridePartiesParams.page.number
          : newPartiesParams.page.size,
      }
      const parties = {
        ...newPartiesParams,
        ...overridePartiesParams,
      }
      const params = {
        ...newPartiesParams,
        filter,
        page,
      }

      const key = { path: `v2/parties`, params }
      mutate(key)
    },
    [shouldUseHistory, newStatusList, newFilter, newPartiesParams, mutate]
  )

  return {
    isLoading,
    isValidating,
    parties: dataTzConverted,
    partiesMeta: meta,
    mutateParties,
    mutatePartiesCustom,
    error,
    key,
  }
}
