import { Grid, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { HelperText } from './Common'
import Select from './Select'
import { convert12HTo24H } from '@/utils/time-utils'

const addLeadingZero = (value: string) => {
  if (parseInt(value) < 10) {
    return String(value).padStart(2, '0')
  }
  return value
}

const generateOptions = (optionType: string) => {
  const numArr = [...Array(13).keys()]
  if (optionType === 'hour') {
    return numArr.map((item, i) => {
      if (i === 0) {
        return {
          label: '',
          value: '',
        }
      }
      return {
        label: item,
        value: item.toString(),
      }
    })
  }
  if (optionType === 'minutes') {
    return numArr.map((item, i) => {
      if (i === 0) {
        return {
          label: '',
          value: '',
        }
      }
      return {
        label: addLeadingZero(String((item - 1) * 5)),
        value: addLeadingZero(String((item - 1) * 5)),
      }
    })
  }
}

export type Meridiem = 'AM' | 'PM'

export interface TimePickerFieldGroupProps {
  name: string
  label?: string
  required?: boolean
  value?: string
  parentError?: string
  handleChange: Function
  setParentError?: Function // to integrate with formik validation if using
  isFieldTouched?: boolean
  setFieldTouched?: Function
  helperText?: string
}
// arbitrary dummy date part for dayjs validation. we will only pull out time string from resulting dayjs date
const dummyDatePart = '2024-02-21'

export const validateHrsMinsTimeString = (str: string) => {
  const regex = /^[0-9]{2}\:[0-9]{2}$/
  return str.match(regex)
}

export const getBestFitMinutes = (minutes?: string) => {
  if (!minutes) {
    return '00'
  }

  const mins = parseInt(minutes)
  const bestFit = addLeadingZero((Math.ceil(mins / 5) * 5).toString())
  return bestFit
}

const TimePickerFieldGroup = ({
  name,
  label,
  required,
  value,
  parentError,
  handleChange,
  setParentError,
  isFieldTouched,
  setFieldTouched,
  helperText,
}: TimePickerFieldGroupProps) => {
  const formattedValue = useMemo(() => {
    if (!value) {
      return {
        hour: '',
        minutes: '',
        meridiem: 'AM',
      }
    }
    const hydratedValue = dayjs(`${dummyDatePart} ${value}`)
    if (hydratedValue.isValid()) {
      const hour = hydratedValue.format('h')
      const minutes = hydratedValue.format('mm')
      const meridiem = hydratedValue.format('A')

      const bestFitMinutes = getBestFitMinutes(minutes)

      return {
        hour,
        minutes: hour && !minutes ? '00' : bestFitMinutes,
        meridiem,
      }
    }

    return {
      hour: '',
      minutes: '',
      meridiem: 'AM',
    }
  }, [value])

  const handleLocalChange = (newVal: string, fieldType: string) => {
    if (setFieldTouched) {
      setFieldTouched(name)
    }
    const meridiem = fieldType === 'meridiem' ? newVal : formattedValue.meridiem
    const hour = fieldType === 'hour' ? newVal : formattedValue.hour
    let minutes = fieldType === 'minutes' ? newVal : formattedValue.minutes

    const combinedTime = convert12HTo24H(
      `${hour}:${minutes}:00`,
      meridiem as Meridiem
    )

    // set error if needed
    if (!validateHrsMinsTimeString(combinedTime)) {
      setParentError(name, 'Invalid time')
    } else {
      setParentError(name, undefined)
    }
    handleChange(`${combinedTime}:00`, name)
  }

  const handleBlur = (value: string) => {
    if (!value) {
      setParentError(name, 'Invalid time')
    } else {
      setParentError(name, undefined)
    }
    if (setFieldTouched) {
      setFieldTouched(name)
    }
  }

  return (
    <Box>
      <Grid container wrap="nowrap">
        <Grid item xs={12}>
          <label
            style={{
              fontFamily:
                'Montserrat, Helvetica, Arial, apple-system, sans-serif',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '21px',
              color: 'rgba(0, 0, 0, 0.87)',
              marginBottom: '4px',
            }}
          >
            {label}
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <Select
            key={`${name}-hour`}
            value={formattedValue?.hour || ''}
            options={generateOptions('hour')}
            name={`${name}.hour`}
            required={required}
            onBlur={(e) => {
              handleBlur(e.target.value)
            }}
            onChange={(e) => {
              return handleLocalChange(e.target.value, 'hour')
            }}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <Select
            key={`${name}-minutes`}
            value={formattedValue?.minutes || ''}
            options={generateOptions('minutes')}
            name={`${name}.minutes`}
            required={required}
            onBlur={(e) => handleBlur(e.target.value)}
            onChange={(e) => {
              return handleLocalChange(e.target.value, 'minutes')
            }}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <Select
            key={`${name}-meridiem`}
            value={formattedValue?.meridiem || 'AM'}
            name={`${name}.meridiem`}
            required={required}
            options={[
              { label: 'AM', value: 'AM' },
              { label: 'PM', value: 'PM' },
            ]}
            onChange={(e) => {
              return handleLocalChange(e.target.value, 'meridiem')
            }}
            style={{
              width: '100%',
            }}
          />
        </Grid>
      </Grid>
      {helperText && <HelperText hasError={false}>{helperText}</HelperText>}
      <HelperText hasError={true}>{isFieldTouched && parentError}</HelperText>
    </Box>
  )
}

export default TimePickerFieldGroup
