import styled from '@emotion/styled'
import colors from '@/styles/Colors.module.scss'
import { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { Party } from '@/api/interface/party'
import { getCounterSuffix } from '@/utils/line-position-utils'
import useLinePositionSelector from '@/hooks/useLinePositionSelector'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import ListboxField from '@/components/tailwindui/common/forms/ListboxField/ListboxField'
import { updatePartyPosition } from '@/api/nextme/parties'
import usePartiesByLocation from '@/hooks/api-hooks/usePartiesByLocation'
import { useDispatch } from 'react-redux'
import { setAlert } from '@/store/slices/alertSlice'
import { AlertTypeEnum } from '@/consts/alert-messages'
import { printAxiosErrors } from '@/utils/util-functions'
import Skeleton from '@/components/tailwindui/common/Skeleton'
import useFilteredParties from '@/hooks/useFilteredParties'

const Badge = styled('span')`
  display: inline-block;
  gap: 8px;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  padding: 3px 8px;
  width: auto;
  min-width: 32px;
  font-size: 14px;
  font-weight: 500;
  max-width: 60px;
`
export interface LinePositionBadgeProps {
  linePosition: number
  party: Partial<Party>
  textColor?: string
  bgColor?: string
  canClickToUpdate?: boolean
  isEditing?: boolean
  isReorderable?: boolean
}

const LinePositionBadge = ({
  linePosition,
  party,
  textColor = 'slate',
  bgColor = 'gray-light',
}: LinePositionBadgeProps) => {
  const suffix = useMemo(() => {
    return getCounterSuffix(linePosition)
  }, [linePosition])

  const dispatch = useDispatch()
  const [refEl, setRefEl] = useState()
  const [popperEl, setPopperEl] = useState()
  const { isValidating, mutateParties } = usePartiesByLocation()
  const { mutateParties: mutateFilteredParties } = useFilteredParties()

  let { styles, attributes } = usePopper(refEl, popperEl, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'document',
          //@ts-ignore
          escapeWithReference: false,
          boundary: 'document',
        },
      },
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'popperOffset',
        options: {
          x: 0,
          y: 6,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  })

  const {
    linePositionMethod,
    linePositionOptions,
    queueName,
    isSubmitting,
    setIsSubmitting,
  } = useLinePositionSelector(party)

  const label = useMemo(() => {
    return `Change position${
      linePositionMethod === 'queue' ? ` within ${queueName}` : ''
    }`
  }, [linePositionMethod, queueName])

  const handleSubmit = useCallback(
    async (value) => {
      const position = value?.value
      if (position !== linePosition) {
        setIsSubmitting(true)
        try {
          const updatedParty = await updatePartyPosition(party, position)
          await mutateFilteredParties()
          dispatch(
            setAlert({
              duration: 3000,
              isOpen: true,
              message: `Your changes have been saved`,
              title: `${party?.guest?.name} Saved`,
              type: AlertTypeEnum.success,
            })
          )
        } catch (err) {
          console.error(err)
          dispatch(
            setAlert({
              duration: 3000,
              isOpen: true,
              title: err.response?.data?.message,
              type: 'error',
              message: printAxiosErrors(err, true),
            })
          )
        } finally {
          setIsSubmitting(false)
        }
      }
    },
    [mutateParties, linePosition]
  )

  return (
    <Popover className="relative inline-flex max-h-[27px]">
      <Popover.Button
        //@ts-ignore state setter is stable between renders and can be used as ref
        // per headless ui docs https://headlessui.com/v1/react/popover#positioning-the-panel
        ref={setRefEl}
      >
        {isSubmitting ? (
          <Skeleton width="33px" height="27px" borderRadius="99px" />
        ) : (
          <Badge
            style={{
              color: textColor,
              backgroundColor: colors[`theme-${bgColor}`],
            }}
          >
            <span>
              <span>{linePosition}</span>
              <span>{suffix}</span>
            </span>
          </Badge>
        )}
      </Popover.Button>

      {linePositionOptions?.length > 1 &&
        !isSubmitting &&
        !isValidating &&
        linePositionMethod && (
          <Popover.Panel
            //@ts-ignore state setter is stable between renders and can be used as ref
            // per headless ui docs https://headlessui.com/v1/react/popover#positioning-the-panel
            ref={setPopperEl}
            className={'z-50 max-w-[160px]'}
            style={styles.popper}
            {...attributes.popper}
          >
            {({ close }) => {
              return (
                <div className="p-2 bg-white">
                  <label className="inline text-sm max-w-[100px] whitespace-normal">
                    {label}
                  </label>
                  <ListboxField
                    name="linePosition"
                    value={linePosition}
                    options={linePositionOptions}
                    handleChange={async (value) => {
                      await handleSubmit(value)
                      close()
                    }}
                  />
                </div>
              )
            }}
          </Popover.Panel>
        )}
    </Popover>
  )
}

export default LinePositionBadge
