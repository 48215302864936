import React from "react";
import { Box } from "@mui/material";
import SvgIcon, { SvgIconProps} from "@mui/material/SvgIcon";   

const StarIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path 
                d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z" 
                fill="#FFC165" 
                stroke="#FFC165" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </SvgIcon>
    )
}


export default StarIcon