import axios from "axios"
import { NotificationSettings } from "../interface/notification-settings"
import { APIFilterParams } from "../interface/utils"

export const getNotificationSettings = <T>(
  params?: APIFilterParams
): Promise<Array<T>> =>
  axios
    .get(`v2/notification-settings`, { params })
    .then((res) => res?.data?.data)

export const getNotificationSettingsById = (id: NotificationSettings["id"]) =>
  axios.get(`v2/notification-settings/${id}`).then((res) => res?.data?.data)

export const createNotificationSettings = (
  notificationSettings: Partial<NotificationSettings>
) =>
  axios
    .post(`v2/notification-settings`, notificationSettings)
    .then((res) => res?.data?.data)

export const updateNotificationSettings = (
  notificationSettings: Partial<NotificationSettings>
) => {

  // cannot ever update a sci if these are included
  delete notificationSettings.nsableId
  delete notificationSettings.nsableType

  return axios
    .patch(
      `v2/notification-settings/${notificationSettings.id}`,
      notificationSettings
    )
    .then((res) => res?.data?.data)
}
  

export const deleteNotificationSettings = (id: NotificationSettings["id"]) =>
  axios.delete(`v2/notification-settings/${id}`).then((res) => res?.data?.data)

  export const restoreNotificationSettings = (id: NotificationSettings["id"]): Promise<any> =>
  axios.patch(`v2/notification-settings/${id}/restore`)
