import dayjs from 'dayjs'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useField } from 'formik'
import { useFormikContext } from 'formik'
import DatePickerInput from './DatePickerInput'
import TimePickerFieldGroup from './TimePickerFieldGroup'
import { validateHrsMinsSecsTimeString } from '@/utils/time-utils'
import useTimezone from '@/hooks/useTimezone'

interface BookingDateTimeSelectorProps {
  name: string
  dateLabel?: string
  timeLabel?: string
}

const combineDateAndTime = (date, time) => {
  const combinedDateTime = dayjs(`${date} ${time}:00`)
  if (combinedDateTime.isValid()) {
    return combinedDateTime.format('YYYY-MM-DD HH:mm:ss')
  }
  return ''
}

const splitDateAndTime = (dateTimeString: string) => {
  const date = getDateFromDateTime(dateTimeString)
  const time = getTimeFromDateTime(dateTimeString)
  return {
    date,
    time,
  }
}

const getDateFromDateTime = (value: string) => {
  return dayjs(value).format('YYYY-MM-DD')
}

const getTimeFromDateTime = (value: string) => {
  return dayjs(value).format('HH:mm:ss')
}

const validateDatePart = (value: string) => {
  return (
    value !== undefined &&
    dayjs(value).isValid() &&
    dayjs().diff(value, 'day') <= 0
  )
}

const validateTimePart = (value: string) => {
  // may need to standardize to HH:mm:ss format
  return validateHrsMinsSecsTimeString(value)
}

export default function BookingDateTimeSelector({
  name,
  dateLabel,
  timeLabel,
}: BookingDateTimeSelectorProps) {
  const [field, meta, helpers] = useField(name)
  const timezone = useTimezone()
  const formattedTz = useMemo(() => {
    return timezone?.split('_')?.join(' ')
  }, [timezone])
  const { setFieldValue, setFieldError, touched, setFieldTouched, errors } =
    useFormikContext()
  // want to remove local state if possible
  const [dateTimeValue, setDateTimeValue] = useState('')
  const [dateValue, setDateValue] = useState('')
  const [timeValue, setTimeValue] = useState('')

  const updateDateTime = useCallback(
    (value: string) => {
      setDateTimeValue(value)
      helpers.setValue(value)
    },
    [helpers, setDateTimeValue]
  )

  const updateFieldPart = useCallback(
    (fieldPart: string, value: string) => {
      const fieldName =
        fieldPart === 'date' ? 'bookingSubfieldDate' : 'bookingSubfieldTime'
      const validator =
        fieldPart === 'date' ? validateDatePart : validateTimePart

      // validate
      const isValid = validator(value)
      if (isValid) {
        const dateVal = fieldPart === 'date' ? value : dateValue
        const timeVal = fieldPart === 'time' ? value : timeValue
        const combinedDateTime = combineDateAndTime(dateVal, timeVal)

        setFieldValue(fieldName, value)
        updateDateTime(combinedDateTime)
        setFieldError(fieldName, undefined)
        if (fieldPart === 'date') {
          setDateValue(value)
        } else {
          setTimeValue(value)
        }
      } else {
        const errorMsg =
          fieldPart === 'date' ? 'Invalid booking date' : 'Invalid booking time'
        setFieldError(fieldName, errorMsg)
      }
    },
    [
      timeValue,
      dateValue,
      dateTimeValue,
      setFieldValue,
      setFieldError,
      setTimeValue,
      setDateValue,
      setDateTimeValue,
    ]
  )

  useEffect(() => {
    // set initial values from bookingSubfieldTime
    if (field?.value?.length && dateTimeValue === '') {
      setDateTimeValue(field?.value)
    }
  }, [field])

  useEffect(() => {
    const { date, time } = splitDateAndTime(dateTimeValue)
    setDateValue(date)
    setTimeValue(time)
  }, [dateTimeValue])

  const handleChange = (newValue: string, fieldName: string = null) => {
    if (fieldName?.length && fieldName === 'bookingSubfieldDate') {
      updateFieldPart('date', newValue)
    }
    if (fieldName?.length && fieldName === 'bookingSubfieldTime') {
      updateFieldPart('time', newValue)
    }
  }

  return (
    <div>
      <DatePickerInput
        required={true}
        name={`bookingSubfieldDate`}
        label={`${dateLabel} *`}
        handleChange={handleChange}
        value={dateValue}
        setParentError={setFieldError}
        setFieldTouched={setFieldTouched}
        isFieldTouched={touched['bookingSubfieldDate']}
        parentError={errors['bookingSubfieldDate']}
      />
      <TimePickerFieldGroup
        required={true}
        name={`bookingSubfieldTime`}
        label={`${timeLabel} *`}
        handleChange={handleChange}
        value={timeValue}
        setParentError={setFieldError}
        parentError={errors['bookingSubfieldTime']}
        setFieldTouched={setFieldTouched}
        isFieldTouched={touched['bookingSubfieldTime']}
        helperText={`Times are in ${
          formattedTz || 'America/Los Angeles'
        } timezone`}
      />
    </div>
  )
}
