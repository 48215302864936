import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Box, Dialog, DialogContent } from "@mui/material"
import { setIsDialogOpen } from "@/store/slices/featureGateSlice";
import styles from "@/styles/Modal.module.css"
import buttonStyles from "@/styles/Buttons.module.scss"
import Link from "next/link";
import { useTypedSelector } from "@/store/index";
import { FEATURE_DIALOG_CONTENT } from "@/consts/Subscriptions";
import StarIcon from "../common/Icon/StarIcon";
import typoStyles from "@/styles/Typography.module.scss"
import classNames from "classnames";

const FeatureGateDialog = () => {
    const [featureDialogContent, setFeatureDialogContent] = useState(null)
    
    const dispatch = useDispatch()
    const {
        isDialogOpen,
        feature,
    } = useTypedSelector((state) => state.featureDialog)

    const closeDialog = () => {
        return () => dispatch(setIsDialogOpen(false))
    }

    const getDialogContent = (feature: string) => {
        setFeatureDialogContent(FEATURE_DIALOG_CONTENT[feature]) 
    }

    useEffect(() => {
        getDialogContent(feature)
    }, [feature])

    return (
      <>
        {featureDialogContent && (
          <Modal open={isDialogOpen} onClose={closeDialog()}>
            <Box className={styles.modalBox} style={{ width: 500 }}>
              <div className="mb-8">
                <h3
                  className={`mb-6 flex gap-2 justify-center ${typoStyles.h6}`}
                >
                  <span>
                    <StarIcon />
                  </span>
                  <span>{featureDialogContent.title}</span>
                </h3>
                <p
                  className="text-center"
                  style={{
                    paddingLeft: feature === 'bookings' ? '30px' : null,
                    paddingRight: feature === 'bookings' ? '30px' : null,
                  }}
                >
                  {featureDialogContent.body}
                </p>
              </div>
              <Box
                className={classNames(styles.modalButtonRow, styles.fitTight)}
              >
                <button
                  type="button"
                  className={buttonStyles.grayBtn}
                  onClick={closeDialog()}
                >
                  Close
                </button>
                <Link href="/business/subscription" passHref>
                  <button
                    type="button"
                    className={buttonStyles.twilightBtn}
                    onClick={closeDialog()}
                  >
                    Upgrade
                  </button>
                </Link>
              </Box>
            </Box>
          </Modal>
        )}
      </>
    )
}

export default FeatureGateDialog