import { refreshIntervals } from "@/consts/swr"
import useSWR from "swr"
import { getSelfCheckIns } from "@/api/nextme/self-check-ins"
import { SelfCheckIn } from "@/api/interface/self-check-in"

export default function useSelfCheckIns() {
  const {
    data: selfCheckIns,
    error,
    isValidating,
    mutate,
  } = useSWR(`/nextme/self-check-ins`, () => getSelfCheckIns(), {
    dedupingInterval: 10000,
    fallbackData: [{} as SelfCheckIn],
    refreshInterval: refreshIntervals.verySlow,
    revalidateOnFocus: false,
  })

  return {
    error,
    isValidating,
    selfCheckIns,
    mutateSelfCheckIns: mutate,
  }
}
