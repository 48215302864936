import { Switch } from '@mui/material'
import { useField } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import formStyles from '@/styles/Forms.module.scss'
import Colors from '@/styles/Colors.module.scss'

interface Props {
  disabled?: boolean
  label?: string
  onChange?: (e: ChangeEvent<any>) => Promise<void> | void
  name?: string
  checked?: boolean
  inputProps?: any
}

export default function SwitchInput({
  disabled,
  label,
  onChange,
  name,
  checked,
  inputProps,
}: Props) {
  const [field, _meta, helpers] = name ? useField(name) : []

  const [isChecked, setIsChecked] = useState(false)
  const [turningOff, setTurningOff] = useState(false)
  const [turningOn, setTurningOn] = useState(false)

  useEffect(() => {
    if (Boolean(checked) || checked === false) {
      setIsChecked(Boolean(checked))
    } else {
      setIsChecked(Boolean(field?.value))
    }
  }, [checked, field, disabled])

  const handleActivateSwitch = async (e: ChangeEvent<any>) => {
    //* switch should change visual state as soon as user clicks despite no response from API yet
    if (!isChecked) {
      setTurningOn(true)
    } else {
      setTurningOff(true)
    }

    let failed: boolean | void

    if (onChange) failed = await onChange(e)

    //* if there was not a failing api call, flip the switch
    if (!failed) {
      helpers?.setValue(!isChecked)
      setIsChecked(!isChecked)
    }

    setTurningOn(false)
    setTurningOff(false)
  }

  return (
    <div className={formStyles.switchContainer}>
      <Switch
        disabled={disabled || turningOn || turningOff}
        checked={turningOn || (!turningOff && isChecked)}
        onChange={handleActivateSwitch}
        inputProps={{
          'aria-label': 'controlled',
          ...inputProps,
        }}
        sx={{
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: Colors['theme-blue'],
            '&+.MuiSwitch-track': {
              backgroundColor: Colors['theme-blue'],
            },
            '&.Mui-disabled': {
              opacity: 0.6,
            },
          },
        }}
      />
      {label && <p className={formStyles.switchLabel}>{label}</p>}
    </div>
  )
}
