import styles from "@/styles/Slide.module.css"

export default function SlideHeading({ title, src }) {
  return (
    <div
      style={{
        width: "100%",
        background: "#fff",
        zIndex: 900,
        padding: "30px 0 20px",
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <img src={src} alt="slider header icon" />
          <p className={styles.headingText}>{title}</p>
        </div>
      </div>
    </div>
  )
}
