import { Listbox } from '@headlessui/react'
import styles from './ListboxField.module.scss'
import classNames from 'classnames'
import { useState } from 'react'

export interface ListboxFieldProps {
  label?: string
  name?: string
  value?: any
  options?: any[]
  handleChange?: Function
}

const ListboxField = ({
  label,
  name,
  value,
  options,
  handleChange,
}: ListboxFieldProps) => {


  const [selectedOption, setSelectedOption] = useState(options.find(option => option.value === value.value))

  return (
    <Listbox
      value={selectedOption}
      onChange={(value) => {
        handleChange(value)
        setSelectedOption(value)
      }}
    >
      <div
        className="relative mt-2"
        style={{
          height: '100px',
        }}
      >
        <Listbox.Options
          static
          className={classNames('group', styles.listboxOptions)}
        >
          {options?.map((option) => {
            return (
              <Listbox.Option
                key={option.value}
                value={option}
                className={styles.listboxOption}
              >
                {option.label}
              </Listbox.Option>
            )
          })}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default ListboxField
