import axios from "axios"
import { Location } from "@/api/interface/location"
import { APIFilterParams } from "../interface/utils"

export const getLocations = (
  params?: APIFilterParams
): Promise<Array<Location>> =>
  axios.get(`v2/locations`, { params }).then((res) => res.data.data)

export const getLocation = (
  id: Location["id"],
  params?: APIFilterParams
): Promise<Location> =>
  axios.get(`v2/locations/${id}`, { params }).then((res) => res.data.data)

export const createLocation = (location: Location): Promise<Location> =>
  axios.post(`v2/locations`, location).then((res) => res.data.data)

export const updateLocation = (
  location: Partial<Location>
): Promise<Location> =>
  axios
    .patch(`v2/locations/${location.id}`, location)
    .then((res) => res.data.data)

export const deleteLocation = (id: Location["id"]) =>
  axios.delete(`v2/locations/${id}`).then((res) => res.data.data)

export const restoreLocation = (id: Location["id"]): Promise<Location> =>
  axios.patch(`v2/locations/${id}/restore`).then((res) => res.data.data)
