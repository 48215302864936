import { useMemo, useEffect, useState } from "react"
import useActiveSubscription from "./useActiveSubscription"
import { setBanner } from "@/store/slices/bannerSlice"
import { useDispatch } from "react-redux"
import { useTypedSelector } from "../store"
import { getSubscriptionMessage } from "@/utils/banner-utils"

const useSubscriptionMessages = () => {
  const dispatch = useDispatch()
  const { subscription, isLoadingSubscription } = useActiveSubscription()
  const { isBannerOpen, dismissedBanners } = useTypedSelector(state => state.banner)
  const [messageData, setMessageData] = useState({
    message: null,
    isMessageVisible: false
  })

  useEffect(() => {
    getSubscriptionMessage(setMessageData, {
      subscription,
      isLoadingSubscription,
      isBannerOpen,
      dismissedBanners,
    })

    if (messageData.isMessageVisible && !dismissedBanners.includes(messageData.message?.id)) {
      dispatch(setBanner({
        messageId: messageData.message?.id,
        isOpen: messageData.isMessageVisible,
        link: messageData.message?.link,
        linkText: messageData.message?.linkText,
        message: messageData.message?.text,
        type: messageData.message?.type,
      }))
    }
  }, [messageData.message, isLoadingSubscription])

  return { isMessageVisible: messageData.isMessageVisible }
}

export default useSubscriptionMessages