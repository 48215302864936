//* if it looks like a hack, just remember that your eyes have tricked you into
//* thinking that this configuration of electrical impulses means something!
export const UserRoleNames: {
  sa: "Super Admin"
  bo: "Business Owner"
  rm: "Regional Manager"
  lm: "Location Manager"
  em: "Employee"
  gu: "Guest (Public)"
} = {
  sa: "Super Admin",
  bo: "Business Owner",
  rm: "Regional Manager",
  lm: "Location Manager",
  em: "Employee",
  gu: "Guest (Public)",
}

//* in order from highest to lowest account level
export const UserRolesOrdered = [
  UserRoleNames.sa,
  UserRoleNames.bo,
  UserRoleNames.rm,
  UserRoleNames.lm,
  UserRoleNames.em,
  UserRoleNames.gu,
]

export type UserRole = typeof UserRolesOrdered[number]
