import { updateParty } from '@/api/nextme/parties'
import usePartiesByLocation from '@/hooks/api-hooks/usePartiesByLocation'
import { useDispatch } from 'react-redux'
import { setAlert } from '@/store/slices/alertSlice'
import { printAxiosErrors } from '@/utils/util-functions'
import { Party } from '@/api/interface/party'
import { ParsedCustomField } from '@/api/interface/custom-field'
import { StyledInput } from './forms/Input/Input'
import TextareaField from '@/components/common/fields/TextareaField'
import { useCallback, useEffect, useState } from 'react'
import useFilteredParties from '@/hooks/useFilteredParties'

interface Props {
  party: Party
  customField: ParsedCustomField
}
export default function CustomFieldEditableField({
  party,
  customField,
}: Props) {
  const dispatch = useDispatch()
  const { parties, partiesMeta, mutateParties } = usePartiesByLocation()
  const { type } = customField
  const [value, setValue] = useState(customField.value)

  useEffect(() => {
    if (customField) {
      setValue(customField.value)
    }
  }, [customField])

  const buildSubmission = useCallback(() => {
    return {
      id: party?.id,
      customFields: [
        {
          ...customField,
          settings: JSON.stringify(customField?.settings),
          value: value,
        },
      ],
    }
  }, [party, customField, value])

  const handleSubmit = async () => {
    const submission = buildSubmission()
    if (value !== customField?.value) {
      try {
        const updatedParty = await updateParty(submission)

        dispatch(
          setAlert({
            isOpen: true,
            message: `${party?.guest?.name}'s info has been saved.`,
            title: `Success`,
            type: 'success',
          })
        )
        await mutateParties(
          { data: parties, meta: partiesMeta },
          {
            optimisticData: (currData) => {
              const { data, meta } = currData
              const newData = data?.map((p) => {
                if (p.id === party.id) {
                  return {
                    ...p,
                    ...submission,
                  }
                }
                return p
              })
              return {
                data: newData,
                meta,
              }
            },
          }
        )
      } catch (err) {
        console.error(printAxiosErrors(err))

        dispatch(
          setAlert({
            isOpen: true,
            title: err.response?.data?.message,
            type: 'error',
            message: printAxiosErrors(err, true),
          })
        )
      }
    }
  }
  return (
    <>
      {type === 'number' && (
        <StyledInput
          data-testid={`editableCf: ${customField.name}`}
          // id={fieldId}
          value={value}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          style={{
            maxWidth: '6em',
            fontSize: '14px',
            padding: '4px 14px',
            height: '2rem',
          }}
          onChange={(e) => {
            const numPattern = /^[0-9]+$/
            if (e.target.value.match(numPattern) || e.target.value === '') {
              setValue(e.target.value)
            }
          }}
          onBlur={async (e) => {
            handleSubmit()
          }}
        />
      )}
      {type === 'string' && (
        <StyledInput
          data-testid={`editableCf: ${customField.name}`}
          // id={fieldId}
          value={value}
          type="text"
          // inputMode="numeric"
          // pattern="[0-9]*"
          style={{
            maxWidth: '6em',
            fontSize: '14px',
            padding: '4px 14px',
            height: '2rem',
          }}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          onBlur={async (e) => {
            handleSubmit()
          }}
        />
      )}
      {/* {type === 'text' && (
        <TextareaField
          data-testid={`editableCf: ${customField.name}`}
          fullWidth={true}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          onBlur={async (e) => {
            handleSubmit()
          }}
        />
      )} */}
    </>
  )
}
