import { bannerMessages } from "../consts/banner-messages"

export const getSubscriptionMessage = (setMessageData, {
  subscription,
  isLoadingSubscription,
  isBannerOpen,
  dismissedBanners,
}) => {
  if (subscription && !isLoadingSubscription) {
    const {
      type,
      trialDaysRemaining,
      messageCount,
      messageLimit,
      status,
    } = subscription

    const usage = messageCount / messageLimit

    if (
      status === 'past_due'
      && !dismissedBanners.includes(bannerMessages.subscription.PAST_DUE.id)
    ) {
      setMessageData({
        message: bannerMessages.subscription.PAST_DUE,
        isMessageVisible: true,
      })
      return
    }

    if (
      messageLimit
      && usage >= .75 && usage < 1
      && !dismissedBanners.includes(bannerMessages.subscription.APPROACHING_LIMIT.id)
    ) {
      setMessageData({
        message: bannerMessages.subscription.APPROACHING_LIMIT,
        isMessageVisible: true,
      })
      return
    }

    if (
      messageLimit
      && usage >= 1
      && !dismissedBanners.includes(bannerMessages.subscription.REACHED_LIMIT.id)
    ) {
      setMessageData({
        message: bannerMessages.subscription.REACHED_LIMIT,
        isMessageVisible: true,
      })
      return
    }

    if (
      type === 'free' 
      && trialDaysRemaining < 0 
      && trialDaysRemaining >= -14
      && !dismissedBanners.includes(bannerMessages.subscription.TRIAL_EXPIRED.id)
    ) {
      setMessageData({
        message: bannerMessages.subscription.TRIAL_EXPIRED,
        isMessageVisible: true,
      })
      return
    }

    if (
      type === 'trial' 
      && trialDaysRemaining < 7
      && trialDaysRemaining >= 0
      && !dismissedBanners.includes(bannerMessages.subscription.TRIAL_EXPIRING.id)
    ) {
      setMessageData({
        message: bannerMessages.subscription.TRIAL_EXPIRING,
        isMessageVisible: true,
      })
      return
    }
  }
}