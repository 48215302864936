import useSWR from "swr"
import { useTypedSelector } from "@/store/index"
import { getCustomFieldsByBusinessId } from "@/api/nextme/custom-fields"
import { refreshIntervals } from "@/consts/swr"

export default function useCustomFieldsByBusinessId() {
  const { businessId } = useTypedSelector((state) => state.selected)

  const {
    data: customFields,
    isValidating,
    mutate,
    error,
  } = useSWR(
    `nextme/customFields-businessId-${businessId}`,
    () => getCustomFieldsByBusinessId(businessId),
    {
      dedupingInterval: 10000, // 10s
      refreshInterval: refreshIntervals.verySlow,
      fallbackData: [],
      //   revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    error,
    isValidating,
    mutate,
    customFields,
  }
}
