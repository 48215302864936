import React from "react"

const WaitlistEntryModalButtons = (props) => {
  return (
    <div className="d-flex align-items-center justify-content-between text-center mt-6">
      {props.children}
    </div>
  )
}

export default WaitlistEntryModalButtons
