import { AlertTypeEnum } from '@/consts/alert-messages'
import { setAlert } from '@/store/slices/alertSlice'
import { printAxiosErrors } from '@/utils/util-functions'
import useFilteredParties from '@/hooks/useFilteredParties'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Party } from '@/api/interface/party'
import PopoverMenu from '@/components/tailwindui/common/Popover/PopoverMenu'
import { updateParty } from '@/api/nextme/parties'
import useQueueSelectorListbox from '@/hooks/useQueueSelectorListbox'

export interface QueueNameProps {
  queueName?: string
  party?: Party
}

const QueueName = ({ queueName, party }) => {
  const dispatch = useDispatch()
  const { mutateParties: mutateFilteredParties } = useFilteredParties()

  const {
    queueMenuOptions,
    isPopoverEnabled,
    isSubmitting,
    listboxLabel,
    setIsSubmitting,
    queueValue,
    setQueueValue,
    mutateWaitTimes,
  } = useQueueSelectorListbox({ queueName, party })

  const buildSubmission = useCallback(
    (option, party: Partial<Party>) => {
      const { id, customFields } = party

      return {
        id,
        queueId: option?.value,
      }
    },
    []
  )

  const handleSubmit = useCallback(
    async (option) => {
      const { label, value } = option
      if (value && value !== queueValue?.value) {
        setIsSubmitting(true)
        try {
          const submission = buildSubmission(option, party)
          const updatedParty = await updateParty(submission)
          setQueueValue(option)
          await mutateFilteredParties()
          await mutateWaitTimes()

          dispatch(
            setAlert({
              duration: 3000,
              isOpen: true,
              message: `${party?.guest?.name} has been moved to ${option?.data?.queueName || label}`,
              title: `Success`,
              type: AlertTypeEnum.success,
            })
          )
        } catch (err) {
          console.error(err)
          dispatch(
            setAlert({
              duration: 3000,
              isOpen: true,
              title: err.response?.data?.message,
              type: 'error',
              message: printAxiosErrors(err, true),
            })
          )
        } finally {
          setIsSubmitting(false)
        }
      }
    },
    [mutateFilteredParties, buildSubmission, queueValue]
  )

  return (
    <div>
      <PopoverMenu
        label={`Change ${listboxLabel}`}
        value={queueValue}
        fieldName="queueName"
        handleChange={handleSubmit}
        isPopoverEnabled={isPopoverEnabled}
        isSubmitting={isSubmitting}
        menuOptions={queueMenuOptions}
        skeletonProps={{
          height: '27px',
          width: '72px',
          borderRadius: '8px',
        }}
      >
        <span>{queueName}</span>
      </PopoverMenu>
    </div>
  )
}

export default QueueName
