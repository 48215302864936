import useSWR from "swr"
import { getLocations } from "@/api/nextme/locations"
import { refreshIntervals } from "@/consts/swr"

export default function useLocations() {
  const {
    data: locations,
    isValidating,
    mutate: mutateLocations,
    error,
  } = useSWR(
    "locations",
    () =>
      getLocations({
        include: ["address", "enabledQueues"],
      }),
    {
      fallbackData: [],
      refreshInterval: refreshIntervals.verySlow,
      dedupingInterval: 10000, // 10s
      revalidateOnFocus: false,
    }
  )

  return {
    isValidating,
    locations,
    mutateLocations,
    error,
  }
}
