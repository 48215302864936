import axios from "axios"
import { Location } from "@/api/interface/location"
import { Party, PartyApiParams, PartyResponseMeta, ReorderPartyApiParams } from "@/api/interface/party"
import { Business } from "../interface/business"
import { Queue } from "../interface/queue"
import { orderByPosition } from "@/utils/parties-utils"


export const getPartiesByLocation = (
  locationId: Location["id"],
  businessId?: Business["id"],
  queueId?: Queue["id"],
  params?: PartyApiParams,
): Promise<{ data: Array<Party>; meta: PartyResponseMeta }> => {

  return axios
    .get(`v2/parties`, {
      params: {
        ...params,
        filter: {
          ...params.filter,
          locationId,
          businessId,
          queueId,
        },
      },
    })
    .then((res) => {
      const { data, links, meta } = res.data
      return {
        data,
        links,
        meta
      }
    })
}     

export const getParties = (
  params?: PartyApiParams
): Promise<{ data: Array<Party>; meta: PartyResponseMeta }> =>
  axios.get(`v2/parties`, { params }).then((res) => res.data)

export const getParty = (partyId: Party["id"]): Promise<Party> =>
  axios.get(`v2/parties/${partyId}`).then((res) => res.data.data)

export const createParty = (party: Partial<Party>) =>
  axios.post(`v2/parties`, party).then((res) => res.data.data)

export const updateParty = (party: Partial<Party>) =>
  axios.patch(`v2/parties/${party.id}`, party).then((res) => res.data.data)


// Change line position
// Reordering by moving a party to another party's position
export const reorderParties = (params: ReorderPartyApiParams) => {
  return axios.get('v2/parties/reorder', { params }).then((res) => res.data)
}

// Move party to a specific position
export const updatePartyPosition = (
  party: Partial<Party> | string,
  position: number
) => {
  const id = typeof party === 'string' ? party : party.id
  return axios.patch(`v2/parties/${id}/reposition/${position}`)
}

/**
 * Deprecated: dailyStats calls replace the need for this, but left for cases where
 * we might need to get more parties than the 100 record api limit allows
 */
const getPartiesRecursive = async (
  parties: any, 
  currentPage: number, 
  lastPage: number, 
  locationId: Location['id'], 
  businessId: Business['id'],
  // startDate: string,
  // endDate: string,
) => {
  if (currentPage >= lastPage) {
    return parties
  }
  // @TODO - update hooks to send all of these params instead of hardcoding here
  // since this is tied closely to analytics use case?
  const newParams = {
    filter: {
      status: 'waiting,notified,confirmed,cancelled_by_guest,cancelled,no_show,walked_out,in_service,completed',
      only_show: 'all',
    },
    page: {
      size: 100,
      number: currentPage + 1,
    },
    // sort: '-created_at', // sort can't be called at the top level of party params and not needed
    include: ['stats'],
    // startsAfter: startDate,
    // endsBefore: endDate,
  }
  const nextParties = await getPartiesByLocation(locationId, businessId, null, newParams)
  return getPartiesRecursive(
    [...parties, ...nextParties.data], 
    nextParties.meta.currentPage, 
    nextParties.meta.lastPage,
    locationId,
    businessId,
    // startDate,
    // endDate,
  )
}

export const getAllPartiesByLocation = async (
  locationId: Location['id'],
  businessId: Business['id'],
  params?: PartyApiParams,
) => {
  try {
    const response = await getPartiesRecursive([], 0, 1, locationId, businessId)
    return response
  } catch (e) {
    console.error(e)
    return []
  }
  
  return []
}
