import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import ListboxField from '@/components/tailwindui/common/forms/ListboxField/ListboxField'
import Skeleton from '@/components/tailwindui/common/Skeleton'
import { useState } from 'react'
import { ReactNode } from 'react'
import type { SkeletonProps } from '@/components/tailwindui/common/Skeleton'

export interface PopoverMenuProps {
  children: ReactNode
  label?: string
  fieldName?: string
  value?: {
    label: string | number
    value: string
    data?: any
  }
  isPopoverEnabled?: boolean
  isSubmitting?: boolean
  menuOptions?: Array<{ label: string; value: string | number }>
  handleChange?: Function
  skeletonProps?: SkeletonProps
}

const PopoverMenu = ({
  children,
  label,
  fieldName,
  value,
  handleChange,
  isPopoverEnabled,
  isSubmitting,
  menuOptions,
  skeletonProps,
}: PopoverMenuProps) => {
  const [refEl, setRefEl] = useState()
  const [popperEl, setPopperEl] = useState()

  let { styles, attributes } = usePopper(refEl, popperEl, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'document',
          //@ts-ignore
          escapeWithReference: false,
          boundary: 'document',
        },
      },
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'popperOffset',
        options: {
          x: 0,
          y: 6,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  })

  return (
    <Popover className="relative inline-flex max-h-[27px]">
      <Popover.Button
        //@ts-ignore state setter is stable between renders and can be used as ref
        // per headless ui docs https://headlessui.com/v1/react/popover#positioning-the-panel
        ref={setRefEl}
      >
        {isSubmitting ? <Skeleton {...skeletonProps} /> : <>{children}</>}
      </Popover.Button>

      {menuOptions?.length > 1 && isPopoverEnabled && !isSubmitting && (
        <Popover.Panel
          //@ts-ignore state setter is stable between renders and can be used as ref
          // per headless ui docs https://headlessui.com/v1/react/popover#positioning-the-panel
          ref={setPopperEl}
          className={'z-50 max-w-[160px]'}
          style={styles.popper}
          {...attributes.popper}
        >
          {({ close }) => {
            return (
              <div className="p-2 bg-white">
                {label && (
                  <label className="inline text-sm max-w-[100px] whitespace-normal">
                    {label}
                  </label>
                )}
                <ListboxField
                  name={fieldName}
                  value={value}
                  options={menuOptions}
                  handleChange={async (value) => {
                    await handleChange(value)
                    close()
                  }}
                />
              </div>
            )
          }}
        </Popover.Panel>
      )}
    </Popover>
  )
}

export default PopoverMenu
