import { useState } from 'react'

export default function useWaitlistUpdates() {
  const [isSubmitting, setIsSubmitting] = useState(false)

  return {
    isSubmitting,
    setIsSubmitting,
  }
}
