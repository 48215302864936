import useBusiness from "./api-hooks/useBusiness";
import { Subscription } from "@/api/interface/subscription";
import moment from "moment";
import { useEffect, useMemo } from "react";


export const getTrialDaysRemaining = (trialEndsAt: string): number => {
    // @TODO - replace moment with Dayjs
    const today = moment()
    const trialEndDate = moment(trialEndsAt)
    return trialEndDate.diff(today, 'days')
}

export const getScheduledForCancellation = (status: string, cancelAt: string) => {
    const today = moment()
    const cancelAtDate = moment(cancelAt)
    const cancelDiff = cancelAtDate.diff(today, 'days')

    return status !== 'canceled' && cancelDiff >= 0
}

export const buildFreePlan = (
    email: string = '', 
    stripeId: string = null,
    messageCount: number = 0,
    previousSubscription: Subscription | null,
) => {
    return {
        billingInterval: null,
        periodEnd: null,
        entitlements: {
            analytics: false,   
            automatedReports: false,
            customBranding: false,
            notificationSettings: true,
            sci: false,
            vwr: false,
            publicWaitlsts: false
        },
        locationLimit: 1,
        messageCap: 500,
        messageCount: messageCount,
        messageCountOverLimit: 0,
        messageLimit: 500,
        price: null,
        subscriptionPlan: null,
        type: 'free',
        userCount: 1,
        userLimit: 1,
        trialDaysRemaining: -20,
        stripeId: stripeId,
        status: 'ended',
        email: email,
        cancelAt: null,
        canceledAt: null,
        isScheduledForCancellation: false,
        previousSubscription,
    } 
}

export const transformPreviousSubscription = (previousSubscriptionPeriod: Subscription | null) => {
    if (!previousSubscriptionPeriod) return null

    const {
        type,
        periodEnd,
    } = previousSubscriptionPeriod
    const trialExpiredLessThanTwoWeeksAgo = type === 'trial' && getTrialDaysRemaining(periodEnd) > -14

    return {
        type,
        periodEnd,
        trialExpiredLessThanTwoWeeksAgo
    }
}

export const buildSubscription = (
    subscription: Subscription | null = null, 
    trialEndsAt: string | null = null, 
    stripeId: string | null = null,
    status: string | null = null,
    email: string | null = null,
    cancelAt: string | null = null,
    canceledAt: string | null = null,
    previousSubscriptionPeriod: Subscription | null = null,
): Subscription => {

    const previousSubscription = transformPreviousSubscription(previousSubscriptionPeriod)
    
    if (subscription) {
        const { 
            entitlements, 
            billingInterval,
            price,
            messageCap,
            messageCount,
            subscriptionPlan, 
            type,
            planType,
        } = subscription

        const freeEntitlements = {
            analytics: false,   
            automated_reports: false,
            customBranding: false,
            notificationSettings: true,
            sci: false,
            vwr: false,
            marketingMessages: false,
            publicWaitlists: false,
        }

        const trialDaysRemaining = getTrialDaysRemaining(trialEndsAt)

        // if there is an expired paid subscription || an expired trial || or a canceledAt on or before today
        if (
            // subscriptionStatuses.INACTIVE.includes(status) ||
            (type === 'trial' && trialDaysRemaining <= 0)
        ) {
            const freePlan = buildFreePlan(email, stripeId, messageCount, previousSubscription)
            // console.log('free plan because inactive or trial expired: ', freePlan)
            return freePlan
            // return buildFreePlan(email, stripeId, messageCount)
        }
    
        return { 
            ...subscription, 
            entitlements: type === 'free' ? freeEntitlements : entitlements,
            trialDaysRemaining,
            price: price ? price : subscriptionPlan?.price,
            stripeId,
            status,
            email,
            type: planType,
            billingInterval: billingInterval ? billingInterval + 'ly' : subscriptionPlan?.billingInterval + 'ly',
            messageCount: messageCount,
            cancelAt,
            canceledAt,
            isScheduledForCancellation: getScheduledForCancellation(status, cancelAt),
            previousSubscription
        }
    }

    // generic free plan if all cases fail
    // console.log('no currentSubscriptionPeriod was returned and a free plan was built')
    return buildFreePlan(email, stripeId, 0, previousSubscription)
}

const useActiveSubscription = () => {
    const { business } = useBusiness()

    if (business) {
        const { currentSubscriptionPeriod, trialEndsAt, stripeId, latestSubscription, email, previousSubscriptionPeriod } = business
        return { 
            isLoadingSubscription: false,
            subscription: buildSubscription(
                currentSubscriptionPeriod, 
                trialEndsAt, 
                stripeId, 
                latestSubscription.stripeStatus,
                email,
                latestSubscription.cancelAt,
                latestSubscription.canceledAt,
                previousSubscriptionPeriod,
            )
        }
    } 

    return { 
        isLoadingSubscription: true,
        subscription: buildSubscription(),
    }
}

export default useActiveSubscription