import modalStyles from "@/styles/Modal.module.css"
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

function WaitlistEntryModalTitle({ icon, title }) {
  return (
    <div className="d-flex align-items-center mb-2">
      <img src={icon} className="me-2" alt="" />
      <h5 className={classNames(modalStyles["custom-modal-title"], "mb-0")}>
        {title}
      </h5>
    </div>
  )
}

WaitlistEntryModalTitle.props = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default WaitlistEntryModalTitle
