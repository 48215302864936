import { defaultCountries, parseCountry } from "react-international-phone"
import { checkIfDialCodeOnly } from "./formik-validators"

export const cleanIntlPhoneForSubmission = (phone) => {
  if (!phone) return ''
  const isDialCodeOnly = checkIfDialCodeOnly(phone)
  if (isDialCodeOnly) return ''
  return phone
}

export const getDefaultCountryFromBizPhone = (phone) => {
  return defaultCountries.reduce((acc, d) => {
    const { dialCode, iso2 } = parseCountry(d)
    const hasCountry = phone.startsWith(dialCode, 1)
    if (hasCountry) {
      acc = iso2 as string
    }
    return acc
  }, '')
}