import { Party } from '@/api/interface/party'
import { checkPossibleArrayValue } from '@/utils/helper-functions'
import CustomFieldActionTooltipEntry from '@/components/common/CustomFieldActionTooltipEntry'
import { parseCfSettings } from '@/utils/custom-field-utils'
import { useMemo } from 'react'
import useDynamicCF from '@/hooks/useDynamicCF'

interface Props {
  party: Party
}

export default function WaitlistEntryCustomFieldAction({ party }: Props) {
  const { customFields } = party
  const { dynamicCustomFields } = useDynamicCF()

  const cfsWithParsedSettings = useMemo(() => {
    return customFields?.map((cf) => {
      return {
        ...cf,
        settings: parseCfSettings(cf.settings),
      }
    })
  }, [customFields])

  const customFieldsWithValue = useMemo(() => {
    return (
      cfsWithParsedSettings?.filter((x) => checkPossibleArrayValue(x.value)) ||
      []
    )
  }, [cfsWithParsedSettings])

  const dynamicCfsWithValue = useMemo(() => {
    return dynamicCustomFields
      ?.filter((cf) => {
        return cf.enabledAt?.includes(party.queueId)
      })
      ?.map((cf) => {
        return {
          ...cf,
          value:
            customFieldsWithValue?.find((field) => field.id === cf.id)?.value ||
            '',
        }
      })
  }, [customFieldsWithValue, dynamicCustomFields])

  return (
    (customFieldsWithValue.length > 0 || dynamicCfsWithValue.length > 0) && (
      <>
        <div>
          {dynamicCfsWithValue.map((x, i) => {
            return (
              <CustomFieldActionTooltipEntry
                key={i}
                customField={x}
                index={i}
                lastIndex={customFieldsWithValue.length - 1}
                party={party}
              />
            )
          })}
          {customFieldsWithValue.map((x, i) => {
            return (
              !x.settings?.isDynamic && (
                <CustomFieldActionTooltipEntry
                  key={i}
                  customField={x}
                  index={i}
                  lastIndex={customFieldsWithValue.length - 1}
                  party={party}
                />
              )
            )
          })}
        </div>
      </>
    )
  )
}
