import { refreshIntervals } from "@/consts/swr"
import useSWR from "swr"
import { getVirtualWaitingRooms } from "@/api/nextme/virtual-waiting-rooms"
import { VirtualWaitingRoom } from "@/api/interface/vwr"

export default function useVirtualWaitingRooms() {
  const {
    data: virtualWaitingRooms,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(`/nextme/vwrs`, () => getVirtualWaitingRooms(), {
    dedupingInterval: 10000,
    fallbackData: [],
    refreshInterval: refreshIntervals.verySlow,
    revalidateOnFocus: false,
  })

  return {
    error,
    isLoading,
    isValidating,
    virtualWaitingRooms,
    mutateVirtualWaitingRooms: mutate,
  }
}
