import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import DashboardLayout from '@/components/layouts/DashboardLayout'
import useBusiness from '@/hooks/api-hooks/useBusiness'
import useIndustries from '@/hooks/api-hooks/useIndustries'
import useLocations from '@/hooks/api-hooks/useLocations'
import useWaitTimesByLocationId from '@/hooks/api-hooks/useWaitTimesByLocationId'
import { setSelectedPartyId } from '@/store/slices/selectedSlice'
import { createParty, updateParty } from '@/api/nextme/parties'
import { setAlert } from '@/store/slices/alertSlice'
import useFilteredParties from '@/hooks/useFilteredParties'
import { printAxiosErrors } from '@/utils/util-functions'
import { AlertTypeEnum } from '@/consts/alert-messages'
import useCustomFields from '@/hooks/api-hooks/useCustomFields'
import { createAddress } from '@/api/nextme/addresses'
import { useTypedSelector } from '@/store/index'
import CreateEditPartyForm from '@/components/pages/waitlist/CreateEditParty/CreateEditPartyForm'
import Slide from '@/components/common/Slide'
import WaitlistFilterForm from '@/components/pages/waitlist/WaitlistFilterForm'
import WaitlistHeader from '@/components/pages/waitlist/WaitlistHeader/WaitlistHeader'
import { setIsSlideOpen } from '@/store/slices/uiSlice'
import { setIsFilterModalOpen } from '@/store/slices/pages/waitlistSlice'
import { WaitlistEntries } from '@/components/pages/waitlist/WaitlistEntries/WaitlistEntries'
import useSelectedQueue from '@/hooks/useSelectedQueue'
import styles from '@/styles/Waitlist.module.scss'
import useSelectedLocation from '@/hooks/api-selected-hooks/useSelectedLocation'

export default function WaitlistPage() {
  const dispatch = useDispatch()
  const [isPartyCreating, setIsPartyCreating] = useState(false)
  const [isPartyUpdating, setIsPartyUpdating] = useState(false)
  const [newPartySkeleton, setNewPartySkeleton] = useState(null)
  const [partyBeingDeleted, setPartyBeingDeleted] = useState(null)

  // TODO: use Suspense/Skeleton to load parts of page that aren't waiting on data (TTI is long here)
  //* region All necessary data for the entire waitlist page
  const { locationId } = useTypedSelector((state) => state.selected)
  const { isFilterModalOpen, partiesParams } = useTypedSelector(
    (state) => state.pages.waitlist
  )
  const { isSlideOpen } = useTypedSelector((state) => state.ui)

  const { locations } = useLocations()
  const { business, isValidating: isBizValidating } = useBusiness()
  const { isValidating: isIndustriesValidating } = useIndustries()

  const {
    waitTimes,
    isValidating: isWtValidating,
    mutateWaitTimes,
  } = useWaitTimesByLocationId(locationId)

  const { selectedLocation } = useSelectedLocation()

  const { selectedQueue } = useSelectedQueue(
    selectedLocation?.enabledQueues || []
  )

  const {
    parties,
    isValidating: isPartiesLoading,
    mutateParties,
    responseMeta: partiesMeta,
  } = useFilteredParties()

  useEffect(() => {
    mutateParties()
  }, [partiesParams])

  //TODO: split this into use by location id and use by business id
  const { customFields, isValidating: isCfValidating } = useCustomFields()
  //* endregion data get

  useEffect(() => {
    //* hack to make first location have an address after business creation
    if (!locations[0] || !business) return

    if (!locations[0].address) {
      createAddress({
        ...business.address,
        addressableId: locations[0].id,
        addressableType: 'location',
        businessId: business.id,
      })
    }
  }, [locations, business])

  const shouldLoadWholePage = useMemo(
    () =>
      isBizValidating ||
      isCfValidating ||
      isIndustriesValidating ||
      isPartiesLoading ||
      isWtValidating,
    [
      isBizValidating,
      isCfValidating,
      isIndustriesValidating,
      isPartiesLoading,
      isWtValidating,
    ]
  )

  //* skeleton effects for party create
  useEffect(() => {
    //* on starting mutate
    if (newPartySkeleton && isPartiesLoading) {
      setIsPartyCreating(true)
    }
  }, [isPartiesLoading, newPartySkeleton])

  useEffect(() => {
    if (isPartyCreating && !isPartiesLoading) {
      setNewPartySkeleton(null)
      setIsPartyCreating(false)
    }
  }, [isPartiesLoading, isPartyCreating])

  //* skeleton effects for party delete
  useEffect(() => {
    if (partyBeingDeleted && isPartiesLoading) {
      setIsPartyUpdating(true)
    }
  }, [isPartiesLoading, partyBeingDeleted])

  useEffect(() => {
    if (isPartyUpdating && !isPartiesLoading) {
      setPartyBeingDeleted(null)
      setIsPartyUpdating(false)
    }
  }, [isPartiesLoading, isPartyUpdating])

  // --------------------------------------
  // PARTY FORM SUBMIT EVENT HANDLERS
  // --------------------------------------
  const onSubmitUpdate = async (formValues, showSuccessAlert = true) => {
    try {
      await updateParty(formValues)

      if (
        formValues.status === 'cancelled' ||
        formValues.status === 'in_service'
      ) {
        setPartyBeingDeleted(formValues.id)
      }

      mutateParties()
      mutateWaitTimes()

      if (showSuccessAlert) {
        dispatch(
          setAlert({
            duration: 3000,
            isOpen: true,
            message: formValues.alertMessage || 'Your changes have been saved.',
            title: formValues.alertTitle || `${formValues.guest.name} Saved`,
            type: AlertTypeEnum.success,
          })
        )
      }

      dispatch(setSelectedPartyId(null))
    } catch (err) {
      console.error(printAxiosErrors(err))

      dispatch(
        setAlert({
          isOpen: true,
          title: err.response?.data?.message,
          type: 'error',
          message: printAxiosErrors(err, true),
        })
      )
    }
  }

  const onSubmitCreate = async (formValues, showSuccessAlert = true) => {
    try {
      const newParty = { ...formValues, locationId }

      await createParty(newParty)

      setNewPartySkeleton(newParty)

      mutateParties()
      mutateWaitTimes()

      if (showSuccessAlert) {
        dispatch(
          setAlert({
            duration: 3000,
            isOpen: true,
            message: formValues.alertMessage || 'Your changes have been saved.',
            title:
              formValues.alertTitle ||
              `${formValues.guest.name} has been saved.`,
            type: AlertTypeEnum.success,
          })
        )
      }

      dispatch(setSelectedPartyId(null))
    } catch (err) {
      console.error(printAxiosErrors(err))

      dispatch(
        setAlert({
          isOpen: true,
          title: err.response?.data?.message,
          type: 'error',
          message: printAxiosErrors(err, true),
        })
      )
    }
  }

  const actions = {
    mutateParties,
    mutateWaitTimes,
    onSubmitUpdate,
    onSubmitCreate,
  }

  return (
    <DashboardLayout
      title="Waitlist"
      shouldLoadWholePage={shouldLoadWholePage}
      headerNavProps={{ isWaitlist: true, showLocationPicker: true }}
    >
      <div className={classNames(styles.pageContainer)}>
        {/* 
            // new mobile style action button
            <div className={classNames(styles.newAddGuestButton)}>+</div> 
        */}

        <WaitlistHeader selectedQueue={selectedQueue} waitTimes={waitTimes} />

        <WaitlistEntries
          actions={actions}
          business={business}
          newPartySkeleton={newPartySkeleton}
          isPartiesLoading={isPartiesLoading}
          parties={parties}
          partiesMeta={partiesMeta}
          partyBeingDeleted={partyBeingDeleted}
          selectedLocation={selectedLocation}
          selectedQueue={selectedQueue}
          waitTimes={waitTimes}
        />

        <Slide
          isOpen={isFilterModalOpen}
          handleClose={() => dispatch(setIsFilterModalOpen(false))}
          style={{ paddingBottom: 180, height: 'auto' }}
        >
          <WaitlistFilterForm
            customFields={customFields}
            handleClose={async () => {
              dispatch(setIsFilterModalOpen(false))
              await mutateParties()
            }}
          />
        </Slide>

        <Slide
          isOpen={isSlideOpen}
          handleClose={() => {
            dispatch(setIsSlideOpen(false))
            dispatch(setSelectedPartyId(null))
          }}
        >
          <CreateEditPartyForm
            actions={actions}
            customFields={customFields}
            parties={parties}
            selectedLocation={selectedLocation}
            waitTimes={waitTimes}
            business={business}
          />
        </Slide>
      </div>
    </DashboardLayout>
  )
}
