import buttonStyles from '@/styles/Buttons.module.scss'
import classNames from 'classnames'
import { MouseEventHandler, ReactNode } from 'react'

interface Props {
  className?: string
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}

export default function WaitlistEntryModalCloseBtn({
  className = buttonStyles.grayBtn,
  disabled,
  onClick,
  children,
}: Props) {
  return (
    <button
      id="closeBtn"
      className={classNames(
        buttonStyles.cardBtn,
        'btn me-4 w-50 p-2',
        className
      )}
      style={{ fontSize: '0.9rem' }}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  )
}
