import { useCallback, useMemo, useState, useEffect } from 'react'

import useSelectedLocation from '../api-selected-hooks/useSelectedLocation'
import useBookingCounts from './useBookingCounts'
import { BookingTimeRange, getPartyEndsBeforeFromRangeStartDate, getPartyStartsAfterFromRangeStartDate } from '@/utils/bookings-utils'
import { useTypedSelector } from '@/store/index'
import { useDispatch } from 'react-redux'
import { setFilters, setIsBookingView, setIsHistory } from '@/store/slices/pages/waitlistSlice'
import { setSelectedBookingTimeRangeName } from '@/store/slices/selectedSlice'
import usePartiesByLocation from './usePartiesByLocation'
import dayjs from 'dayjs'

/**
 * This hook manages state for the bookings tabs on the waitlist.
 */

export default function useBookingParties() {
  const dispatch = useDispatch()
  const { selectedLocation } = useSelectedLocation()
  const { bookingCounts, mutateBookingCounts, isBookingCountsLoading, isBookingCountsValidating } = useBookingCounts()
  const { filters, isBookingView } = useTypedSelector((state) => state.pages.waitlist)
  const [selectedRange, setSelectedRange] = useState<BookingTimeRange>(null)
  const { mutatePartiesCustom } = usePartiesByLocation()

  const badgeCount = useMemo(() => {
    // as any[] added for a ts issue fixed in a later version of ts
    const count = Array.isArray(bookingCounts) && (bookingCounts as any[])?.find((count) => count.name === 'today')
    return count?.count && typeof count?.count === 'number' ? count?.count : 0

  }, [bookingCounts])

  const isBookingEnabled = useMemo(() => selectedLocation?.isBookingsEnabled, [selectedLocation])

  const firstTimeRange = useMemo(() => bookingCounts?.[0], [bookingCounts])

  const setSelectedBookingTimeRange = useCallback((range) => {
    setSelectedRange(range)
    dispatch(setSelectedBookingTimeRangeName(range.name))
    dispatch(
      setFilters({
        ...filters,
        isBooking: true,
        startsAfter: getPartyStartsAfterFromRangeStartDate(range.startDate) || dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
        endsBefore: getPartyEndsBeforeFromRangeStartDate(range.endDate),
      })
    )
  }, [setSelectedRange])

  const toggleBookingsView = useCallback(() => {
    setSelectedRange(firstTimeRange)
    dispatch(setSelectedBookingTimeRangeName(firstTimeRange?.name))
    if (Boolean(filters?.isBooking)) {
      // toggling off
      dispatch(
        setIsBookingView(false)
      )
      dispatch(
        setFilters({
          ...filters,
          isBooking: null,
          startsAfter: null,
          endsBefore: null,
        })
      )
    }
    if (!Boolean(filters?.isBooking)) {
      // toggling on
      const newFilters = {
        ...filters,
        isBooking: true,
        startsAfter: getPartyStartsAfterFromRangeStartDate(firstTimeRange?.startDate) || dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        endsBefore: getPartyEndsBeforeFromRangeStartDate(firstTimeRange?.endDate) || dayjs().startOf('day').format('YYYY-MM-DD'),
      }
      dispatch(
        setIsBookingView(true)
      )
      dispatch(
        setFilters(newFilters)
      )
      mutatePartiesCustom({ filter: newFilters })
      dispatch(
        setIsHistory(false)
      )
    }
  }, [filters, setSelectedRange, selectedRange, setFilters, dispatch, firstTimeRange])

  useEffect(() => {
    if (!selectedRange && firstTimeRange) {
      setSelectedRange(firstTimeRange)
      dispatch(setSelectedBookingTimeRangeName(firstTimeRange?.name))
    }
  }, [selectedRange, firstTimeRange])

  return {
    isBookingEnabled,
    isBookingCountsLoading,
    isBookingCountsValidating,
    isBookingView,
    badgeCount,
    bookingCounts,
    selectedBookingTimeRange: selectedRange,
    mutateBookingCounts,
    setSelectedBookingTimeRange,
    toggleBookingsView,
  }
}