import styles from "@/styles/Waitlist.module.scss"
import { setPageNumber } from "@/store/slices/pages/waitlistSlice"
import React from "react"
import { useDispatch } from "react-redux"

export const WaitlistEntriesPagination = ({
  responseMeta,
  newPartySkeleton,
}) => {
  const dispatch = useDispatch()

  if (!responseMeta) {
    return null
  }
  // when filtered, calculate page numbers from filtered results
  // not updating
  const pageNumbers = []
  for (let x = 1; x <= parseInt(responseMeta?.lastPage); x++) {
    pageNumbers.push(x)
  }

  return (
    <section className={styles.paginationWrapper}>
      <header className={styles.paginationHeader}>
        Showing {responseMeta?.from + (!!newPartySkeleton ? 1 : 0)} -{" "}
        {responseMeta?.to + (!!newPartySkeleton ? 1 : 0)} of{" "}
        {responseMeta?.total + (!!newPartySkeleton ? 1 : 0)}
      </header>
      {responseMeta?.lastPage > 1 ? (
        <ul className={styles.pagination}>
          {pageNumbers.map((n) => {
            const liClass =
              n === parseInt(responseMeta?.currentPage)
                ? styles.activePaginationItem
                : ""

            return (
              <li
                key={`page-${n}`}
                onClick={() => {
                  dispatch(setPageNumber(n))
                }}
                className={liClass}
              >
                {n}
              </li>
            )
          })}
        </ul>
      ) : null}
    </section>
  )
}
