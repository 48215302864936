export const bannerMessages = {
  subscription: {
    TRIAL_EXPIRING: {
      id: 1,
      text: '⌛ Your trial period is expiring soon. Upgrade now to retain your premium data and features.',
      type: 'info',
      link: '/business/subscription',
      linkText: 'Learn More',
    },
    TRIAL_EXPIRED: {
      id: 2,
      text: '⌛ Your trial period has expired. Upgrade now to retain your premium data.',
      type: 'warning',
      link: '/business/subscription',
      linkText: 'Learn More',
    },
    APPROACHING_LIMIT: {
      id: 3,
      text: '💬 You\’ve used 75% of your message limit this billing period.',
      type: 'info',
      link: '/business/subscription',
      linkText: 'Learn More',
    },
    REACHED_LIMIT: {
      id: 4,
      text: '💬 You\’ve reached your message limit this billing period.',
      type: 'warning',
      link: '/business/subscription',
      linkText: 'Learn More',
    },
    PAST_DUE: {
      id: 5,
      text: '⚠️ Your payment is past due. Please review and pay your invoice to avoid service interruption. ',
      type: 'warning', 
      link: 'https://billing.stripe.com/p/login/fZe29n4mvaU7gmscMM',
      linkText: 'Pay Now',
    }
  }
}

export const BANNER_COLORS = {
  warning: '#E85757',
  info: '#202b7e'
}

