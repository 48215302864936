/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export const activeStatuses = [
  {
    label: 'Waiting',
    value: 'waiting',
  },
  {
    label: 'Returning',
    value: 'returning',
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Notified',
    value: 'notified',
  },
  {
    label: 'Checked In',
    value: 'checked_in',
  },
  {
    label: 'Confirmed',
    value: 'confirmed',
  },
  {
    label: 'Cancelled (By Guest)',
    value: 'cancelled_by_guest',
  },
]

export const historicalStatuses = [
  {
    label: "In-Service",
    value: "in_service",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Walked-out",
    value: "walked_out",
  },
  {
    label: "No Show",
    value: "no_show",
  },
]
