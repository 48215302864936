import { useMemo } from 'react';
import useSWR from 'swr'
import { getBookingCounts } from '@/api/nextme/bookings-counts'
import { BookingTimeRangeNames, buildBookingTimeRangeQuery } from '@/utils/bookings-utils'
import useTimezone from '../useTimezone'
import { refreshIntervals } from '@/consts/swr'
import { useTypedSelector } from '@/store/index';

export default function useBookingCounts() {
  const timezone = useTimezone()
  const { locationId } = useTypedSelector((state) => state.selected)
  const query = useMemo(() => buildBookingTimeRangeQuery(BookingTimeRangeNames, timezone), [timezone])

  const { data, isLoading, isValidating, mutate, error } = useSWR(
    `v2/bookingss/counts`,
    () => (locationId ? getBookingCounts(locationId, query) : null),
    { refreshInterval: refreshIntervals.quicker }
  )

  // add label to data
  const hydratedBookingCounts = useMemo(() => {
    if (!data) {
      // return defaults
      return BookingTimeRangeNames.map((range) => {
        return {
          ...range,
          count: 'loading',
          startDate: null,
          endDate: null,
        }
      })
    }
    return data?.map((d) => {
      const { label } = BookingTimeRangeNames.find((r) => r.name === d.name)
      const { startDate, endDate } = query.find((r) => r.name === d.name)
      return {
        ...d,
        label,
        startDate,
        endDate,
      }
    })
  }, [data])

  return {
    bookingCounts: hydratedBookingCounts,
    isBookingCountsLoading: isLoading,
    isBookingCountsValidating: isValidating,
    mutateBookingCounts: mutate,
    bookingCountsError: error,
  }
}