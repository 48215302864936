import { useTypedSelector } from '@/store/index'
import FilledIconButton from './FilledIconButton'
import { setIsSearchbarOpen } from '@/store/slices/uiSlice'
import { useDispatch } from 'react-redux'
import { Search, X } from 'react-feather'
import { useState } from 'react'
import {
  setIsFiltered,
  setPartiesFilter,
} from '@/store/slices/pages/waitlistSlice'
import useFilteredParties from '@/hooks/useFilteredParties'
import { useDebounce } from 'react-use'

export default function HeaderSearchBar() {
  const dispatch = useDispatch()
  const { isSearchbarOpen } = useTypedSelector((state) => state.ui)
  const { isValidating } = useFilteredParties()

  const [searchTerm, setSearchTerm] = useState('')

  useDebounce(
    () => {
      dispatch(setPartiesFilter({ ...(searchTerm ? { s: searchTerm } : {}) }))
      // dispatch(setIsFiltered(searchTerm?.length > 0))
    },
    500,
    [searchTerm]
  )

  return (
    <FilledIconButton
      disableRipple={true}
      style={{ color: 'rgba(29, 29, 29, 1)' }}
      textDisplayOverride="hideBelow1280"
      onClick={() => {
        if (!isSearchbarOpen) dispatch(setIsSearchbarOpen(true))
      }}
      text={isSearchbarOpen ? null : 'Search...'}
      isExpanded={isSearchbarOpen}
    >
      <>
        <Search />
        {isSearchbarOpen && (
          <div className="ml-2 w-full absolute flex items-center">
            <input
              placeholder="Search by name or phone..."
              className={`bg-transparent absolute left-6 right-[3.25rem] flex outline-none text-lg font-medium ${
                !isValidating && 'text-black'
              }`}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
            />
            <a
              className="absolute right-5 h-full flex items-center"
              onClick={() => {
                setSearchTerm('')
                dispatch(setIsSearchbarOpen(false))
              }}
            >
              <X />
            </a>
          </div>
        )}
      </>
    </FilledIconButton>
  )
}
