import React, { useEffect, useState } from "react"

const useIsTouchable = () => {
  const [isTouchable, setIsTouchable] = useState(false)

  const onResize = () => {
    setIsTouchable(window.matchMedia("(pointer: coarse)").matches)
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return isTouchable
}

export default useIsTouchable
