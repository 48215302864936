import React from "react"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import styles from "@/styles/Slide.module.css"
import classNames from "classnames"
import { useMobileOrTabletQuery } from "@/utils/responsive-queries"

const LeftTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const UpTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const SlideContainer = ({ children }) => {
  const isMobileOrTablet = useMobileOrTabletQuery()
  return (
    <div
      className={classNames(
        styles["slide-container"],
        isMobileOrTablet && styles["slide-container-mobile"]
      )}
    >
      {children}
    </div>
  )
}

export const SlideContent = ({ children }) => {
  const isMobileOrTablet = useMobileOrTabletQuery()
  return (
    <div
      className={classNames(
        styles["slide-content"],
        isMobileOrTablet && styles["slide-content-mobile"]
      )}
    >
      {children}
    </div>
  )
}

export const SlideActionButtons = ({ children }) => {
  const isMobileOrTablet = useMobileOrTabletQuery()
  return (
    <div
      className={classNames(
        styles["slide-action-buttons"],
        isMobileOrTablet && styles["slide-action-buttons-mobile"]
      )}
    >
      {children}
    </div>
  )
}

export default function RightSlide({
  children,
  isOpen,
  handleClose,
  style = null,
}) {
  const isMobileOrTablet = useMobileOrTabletQuery()

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={isMobileOrTablet ? UpTransition : LeftTransition}
      className={styles.dialog}
    >
      <div className={styles.main} style={style}>
        {children}
      </div>
    </Dialog>
  )
}
