import { useEffect, useMemo, useState } from "react"
import useAccount from "./api-hooks/useAccount"

const usePermissions = (permission: string) => {
  const { account } = useAccount()
  const [hasPermissions, setHasPermissions] = useState(false)

  const checkPermissions = () => {
    setHasPermissions(account?.permissions?.includes(permission))
  }

  useEffect(() => {
    checkPermissions()
  }, [account])

  return {
    hasPermissions
  }
}

export default usePermissions