import useWaitTimesByLocationId from '@/hooks/api-hooks/useWaitTimesByLocationId'
import useFilteredParties from '@/hooks/useFilteredParties'
import { useTypedSelector } from '@/store/index'
import { IconButton } from '@mui/material'
import {
  Clock,
  Menu,
  Plus,
  RefreshCw,
  Sliders,
  Users,
  Calendar,
} from 'react-feather'
import LocationPicker from '../LocationPicker'
import WaitlistStyles from '@/styles/Waitlist.module.scss'
import classNames from 'classnames'
import FilledIconButton from './FilledIconButton'
import { useDispatch } from 'react-redux'
import {
  setPageNumber,
  setPartiesFilter,
  toggleHistoryFilter,
} from '@/store/slices/pages/waitlistSlice'
import { usePhoneOnlyQuery } from '@/utils/responsive-queries'
import {
  setIsSearchbarOpen,
  setIsSidebarOpen,
  setIsSlideOpen,
} from '@/store/slices/uiSlice'
import HeaderSearchBar from './HeaderSearchBar'
import useActiveSubscription from '@/hooks/useActiveSubscription'
import { setDialog } from '@/store/slices/featureGateSlice'
import { useMemo } from 'react'
import useBannerHeight from '@/hooks/useBannerHeight'
import { createPublicWlUrl } from '@/utils/public-waitlist-utils'
import { setIsFilterModalOpen } from '@/store/slices/pages/waitlistSlice'
import useSelectedWaitlist from '@/hooks/api-selected-hooks/useSelectedWaitlist'
import CountBadge from '../CountBadge'
import { setSelectedQueueId } from '@/store/slices/selectedSlice'
import useBookingParties from '@/hooks/api-hooks/useBookingParties'

interface Props {
  title: string
  isWaitlist?: boolean
  showLocationPicker?: boolean
  isBannerVisible?: boolean
}

export default function HeaderNav({
  title,
  isWaitlist,
  showLocationPicker,
  isBannerVisible,
}: Props) {
  const dispatch = useDispatch()
  const isPhoneUser = usePhoneOnlyQuery()
  const bannerHeight = useBannerHeight()

  const { businessId, locationId } = useTypedSelector((state) => state.selected)
  const { subscription } = useActiveSubscription()
  const { selectedWaitlist } = useSelectedWaitlist()

  const { filters, isFiltered } = useTypedSelector(
    (state) => state.pages.waitlist
  )
  const { hasOnlyOneLocation } = useTypedSelector((state) => state.pages.manage)
  const { isSidebarOpen, isSearchbarOpen } = useTypedSelector(
    (state) => state.ui
  )

  const {
    badgeCount,
    isBookingEnabled,
    isBookingView,
    toggleBookingsView,
    mutateBookingCounts,
  } = useBookingParties()

  const { mutateParties, isValidating } = useFilteredParties()
  const { mutateWaitTimes } = useWaitTimesByLocationId(locationId)

  const publicWaitlistUrl = useMemo(() => {
    if (!+selectedWaitlist?.enabled) return null
    return createPublicWlUrl(selectedWaitlist?.id)
  }, [selectedWaitlist])

  const refreshWaitlist = () => {
    mutateParties()
    mutateWaitTimes()
  }

  return (
    <div
      className={`grid grid-flow-col grid-cols-12 sm:grid-cols-12 h-20 fixed top-0 w-full items-center pr-4 py-0 bg-white ${
        isPhoneUser ? 'pl-2' : isSidebarOpen ? 'pl-[17rem]' : 'pl-4 z-10'
      }`}
      style={{
        boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
        zIndex: '2',
        marginTop: `${bannerHeight}px`,
      }}
    >
      <section
        className={classNames(
          'flex items-center gap-0.5 xs:gap-2 sm:gap-2 col-span-3 xs:col-span-6 sm:col-span-6 md:col-span-6 2xl:col-span-6 xl:col-span-4 lg:col-span-4'
        )}
      >
        {!isSidebarOpen && (
          <IconButton
            disableRipple={true}
            style={{ color: 'rgba(29, 29, 29, 1)' }}
            onClick={() => {
              dispatch(setIsSidebarOpen(true))
            }}
          >
            <Menu />
          </IconButton>
        )}
        <h1
          className={`text-slate font-semibold break-keep ${
            isPhoneUser ? 'text-xl pl-0' : 'text-2xl pl-4'
          } ${isPhoneUser && isWaitlist ? 'hidden' : null}`}
        >
          {title}
        </h1>
        {!hasOnlyOneLocation && showLocationPicker && (
          <LocationPicker
            className={`mb-auto mt-1 ${
              isPhoneUser ? 'w-[100px] mx-0' : 'ml-4 mr-2'
            }`}
            style={{ width: isPhoneUser ? 100 : 'auto' }}
          />
        )}
        {isWaitlist && !isPhoneUser && (
          <IconButton
            //   id="waitlist-header-refresh-btn"
            className={classNames(
              WaitlistStyles.headerRefreshBtn,
              isValidating && WaitlistStyles.spin
            )}
            disableRipple={true}
            style={{ color: 'rgba(29, 29, 29, 1)' }}
            onClick={refreshWaitlist}
          >
            <RefreshCw />
          </IconButton>
        )}
      </section>
      <section
        className={classNames(
          'flex justify-self-end gap-0.5 xs:gap-2 sm:gap-3 col-span-9 xs:col-span-6 sm:col-span-6 md:col-span-6 2xl:col-span-6 xl:col-span-8 lg:col-span-8'
        )}
      >
        {isWaitlist && (
          <>
            {Boolean(isBookingEnabled) && (
              <div style={{ position: 'relative' }}>
                <FilledIconButton
                  key="bookings-view-toggle"
                  color={isBookingView ? 'white' : 'slate'}
                  backgroundColor={isBookingView ? 'bg-blue' : 'bg-gray-light'}
                  text="Bookings"
                  onClick={async () => {
                    toggleBookingsView()
                    dispatch(setSelectedQueueId(null))
                    dispatch(setPageNumber(1))

                    await mutateParties()
                    await mutateWaitTimes()
                    await mutateBookingCounts()
                  }}
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <Calendar />
                </FilledIconButton>
                {badgeCount > 0 && !isSearchbarOpen && (
                  <CountBadge count={badgeCount} />
                )}
              </div>
            )}

            <FilledIconButton
              color={filters.isHistory ? 'white' : 'slate'}
              backgroundColor={filters.isHistory ? 'bg-red' : 'bg-gray-light'}
              text="History"
              onClick={() => {
                dispatch(toggleHistoryFilter())
                dispatch(setPageNumber(1))
                mutateWaitTimes()
              }}
            >
              <Clock />
            </FilledIconButton>
            <FilledIconButton
              color={isFiltered ? 'white' : 'slate'}
              backgroundColor={isFiltered ? 'bg-blue' : 'bg-gray-light'}
              text="Filter"
              onClick={() => dispatch(setIsFilterModalOpen(true))}
            >
              <Sliders />
            </FilledIconButton>
            <HeaderSearchBar />
            <FilledIconButton
              color="white"
              backgroundColor="bg-green"
              text="Add Guest"
              fab={{ active: !!isPhoneUser, order: 0 }}
              // Onclick - if messageCount >= messageCap, display feature gate modal
              onClick={() => {
                if (
                  subscription?.messageCap &&
                  subscription?.messageCount > 0 &&
                  subscription?.messageCount >= subscription?.messageCap
                ) {
                  return dispatch(
                    setDialog({
                      isDialogOpen: true,
                      size: 'md',
                      feature: 'message_cap',
                    })
                  )
                }
                dispatch(setIsSlideOpen(true))

                dispatch(setIsSearchbarOpen(false))
                dispatch(setPartiesFilter({}))
              }}
            >
              <Plus />
            </FilledIconButton>
            {isPhoneUser && (
              <FilledIconButton
                backgroundColor="bg-white"
                fab={{ active: true, order: 1 }}
                onClick={refreshWaitlist}
              >
                <RefreshCw
                  className={classNames(isValidating && WaitlistStyles.spin)}
                />
              </FilledIconButton>
            )}
          </>
        )}
      </section>
    </div>
  )
}
