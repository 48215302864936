import axios from "axios"
import {
  PublicVwrData,
  VirtualWaitingRoom,
  VwrMedia,
} from "@/api/interface/vwr"
import { Media, PartialMediaWithId, RepositionMediaQuery } from '@/api/interface/media'
import { Guest } from '../interface/guest'
import { Party } from '../interface/party'
import { enforceGuid } from '@/utils/util-functions'

const parsedIncomingSettings = (vwrSettings: VirtualWaitingRoom['settings']) =>
  typeof vwrSettings === 'string' ? JSON.parse(vwrSettings) : vwrSettings

const stringifiedOutgoingSettings = (
  vwrSettings: VirtualWaitingRoom['settings']
) =>
  typeof vwrSettings === 'string' ? vwrSettings : JSON.stringify(vwrSettings)

export const getVirtualWaitingRooms = (): Promise<Array<VirtualWaitingRoom>> =>
  axios
    .get(`v2/virtual-waiting-rooms`, {
      params: { include: ['vwrable'], filter: { trashed: 'with' } },
    })
    .then((res) =>
      res.data.data?.map((x) => ({
        ...x,
        settings: parsedIncomingSettings(x.settings),
      }))
    )

export const getVirtualWaitingRoom = (
  id: VirtualWaitingRoom['id']
): Promise<VirtualWaitingRoom> =>
  axios
    .get(`v2/virtual-waiting-rooms/${id}`, {
      params: { include: ['vwrable'] },
    })
    .then((res) => ({
      ...res.data.data,
      settings: parsedIncomingSettings(res.data.data?.settings),
    }))

export const getPublicWaitingRoom = (
  guestId: Guest['id'],
  partyId: Party['id']
): Promise<PublicVwrData> =>
  axios
    .get(
      `v2/public/waiting-rooms/${enforceGuid(partyId)}/${enforceGuid(guestId)}`,
      {
        params: { include: ['vwrable'] },
      }
    )
    .then((res) => ({
      ...res.data.data,
      virtualWaitingRoom: {
        ...res.data.data.virtualWaitingRoom,
        settings: parsedIncomingSettings(
          res.data.data.virtualWaitingRoom.settings
        ),
      },
    }))

export const createVirtualWaitingRoom = async (
  vwr: Partial<VirtualWaitingRoom>
): Promise<VirtualWaitingRoom> =>
  axios
    .post(`v2/virtual-waiting-rooms`, {
      ...vwr,
      settings: stringifiedOutgoingSettings(vwr.settings),
    })
    .then((res) => res.data.data)

export const updateVirtualWaitingRoom = async (
  vwr: Partial<VirtualWaitingRoom>
): Promise<VirtualWaitingRoom> => {
  const { vwrableId, vwrableType, ...submission } = vwr

  return axios
    .patch(`v2/virtual-waiting-rooms/${submission.id}`, {
      ...submission,
      settings: stringifiedOutgoingSettings(submission.settings),
    })
    .then((res) => res.data.data)
}

export const deleteVirtualWaitingRoom = async (
  vwrId: VirtualWaitingRoom['id']
): Promise<VirtualWaitingRoom> =>
  axios.delete(`v2/virtual-waiting-rooms/${vwrId}`).then((res) => res.data.data)

export const restoreVirtualWaitingRoom = (
  id: VirtualWaitingRoom['id']
): Promise<any> => axios.patch(`v2/virtual-waiting-rooms/${id}/restore`)

export const createVWRMedia = async (
  vwrId: VirtualWaitingRoom['id'],
  vwrMedia: FormData
): Promise<VwrMedia> =>
  axios
    .post(`v2/virtual-waiting-rooms/${vwrId}/media`, vwrMedia, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data.data)

export const deleteVWRMedia = async (
  vwrId: VirtualWaitingRoom['id'],
  mediaId: Media['id']
): Promise<VwrMedia> =>
  axios
    .delete(`v2/virtual-waiting-rooms/${vwrId}/media/${mediaId}`)
    .then((res) => res.data)

export const updateVWRMediaPosition = async (
  vwrId: string,
  mediaId: Media | number,
  position: number
) => {
  const id = typeof mediaId === 'number' ? mediaId : mediaId.id
  return axios.patch(
    `v2/virtual-waiting-rooms/${vwrId}/media/${id}/reposition/${position}`
  )
}

export const reorderVWRMedia = async (vwrId: VirtualWaitingRoom['id'], repositionParams: RepositionMediaQuery[]) => {
  return axios.patch(
    `v2/virtual-waiting-rooms/${vwrId}/media/reorder`, 
    { changes: repositionParams }
  ).then((res) => res.data)
}