import axios from "axios"
import { Industry } from "../interface/industry"

export const getIndustries = (): Promise<Array<Industry>> =>
  axios
    .get(`v2/industries`, { params: { sort: "name" } })
    .then((res) => res.data.data)

export const getIndustry = (id: Industry["id"]): Promise<Industry> =>
  axios.get(`v2/industries/${id}`).then((res) => res.data.data)
