import React, { Component, ErrorInfo } from "react";
import { captureException, captureMessage } from "@sentry/nextjs";

interface Props {
  children: React.ReactNode
  fallback: React.ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log to Sentry here
    captureException(error)
    captureMessage(JSON.stringify(errorInfo))
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }

}

export default ErrorBoundary