import classNames from "classnames"
import { setSelectedPartyId } from "@/store/slices/selectedSlice"
import { setIsSlideOpen } from "@/store/slices/uiSlice"
import React, { Dispatch } from "react"
import { useDispatch } from "react-redux"
import styles from "@/styles/Waitlist.module.scss"
import buttonStyles from "@/styles/Buttons.module.scss"
import { Party } from '@/api/interface/party'
import { useDesktopUpQuery } from '@/utils/responsive-queries'

interface Props {
  party: Party
  setShowPartyActionModal: Dispatch<string | null>
}

export default function WaitlistEntryActions({
  party,
  setShowPartyActionModal,
}: Props) {
  const dispatch = useDispatch()
  const isDesktopUser = useDesktopUpQuery()

  return (
    <>
      {/** NOTIFY */}
      {(party.status === 'waiting' ||
        party.status === 'returning' ||
        party.status === 'notified') && (
        <button
          className={classNames(
            styles.entryAction,
            styles.entryActionButton,
            buttonStyles.yellowBtn
          )}
          onClick={() => {
            dispatch(setSelectedPartyId(party.id))
            setShowPartyActionModal('notify')
          }}
        >
          <div className="flex gap-2 justify-center align-middle items-center">
            <img src="/icons/notify-action.svg" alt="Notify" />
            {isDesktopUser && <span>Notify</span>}
          </div>
        </button>
      )}

      {/** CHECKIN */}
      {(party.status === 'scheduled' || party.status === 'confirmed') && (
        <button
          className={classNames(
            styles.entryAction,
            styles.entryActionButton,
            buttonStyles.greenBtn
          )}
          onClick={() => {
            dispatch(setSelectedPartyId(party.id))
            setShowPartyActionModal('checkin')
          }}
        >
          <div className="flex gap-2 justify-center align-middle items-center">
            <img src="/icons/map-pin-white.svg" alt="Check In" />
            {isDesktopUser && <span>Check In</span>}
          </div>
        </button>
      )}

      {/** SERVICE */}
      {(party.status === 'waiting' ||
        party.status === 'returning' ||
        party.status === 'notified' ||
        party.status === 'scheduled' ||
        party.status === 'confirmed' ||
        party.status === 'checked_in') && (
        <button
          className={classNames(
            styles.entryAction,
            styles.entryActionButton,
            buttonStyles.blueBtn
          )}
          onClick={() => {
            dispatch(setSelectedPartyId(party.id))
            setShowPartyActionModal('service')
          }}
        >
          <div className="flex gap-2 justify-center align-middle items-center">
            <img src="/icons/service-action.svg" alt="Service" />
            {isDesktopUser && <span>Service</span>}
          </div>
        </button>
      )}

      {/** COMPLETED */}
      {party.status === 'in_service' && (
        <button
          className={classNames(
            styles.entryAction,
            styles.entryActionButton,
            buttonStyles.twilightBtn
          )}
          onClick={() => {
            dispatch(setSelectedPartyId(party.id))
            setShowPartyActionModal('complete')
          }}
        >
          <div className="flex gap-2 justify-center align-middle">
            <img src="/icons/service-action.svg" alt="Complete" />
            {isDesktopUser && <span>Complete</span>}
          </div>
        </button>
      )}

      {/** RESTORE */}
      {(party.status === 'cancelled' ||
        party.status === 'walked_out' ||
        party.status === 'no_show' ||
        party.status === 'completed' ||
        party.status === 'in_service') && (
        <button
          className={classNames(
            styles.entryAction,
            styles.entryActionButton,
            buttonStyles.blueBtn
          )}
          onClick={() => {
            dispatch(setSelectedPartyId(party.id))
            setShowPartyActionModal('restore')
          }}
        >
          <div className="flex gap-2 justify-center align-middle items-center">
            <img src="/icons/corner-up-left-white.svg" alt="Restore" />
            {isDesktopUser && <span>Restore</span>}
          </div>
        </button>
      )}

      {/** REMOVE */}
      {party.status !== 'cancelled' &&
        party.status !== 'walked_out' &&
        party.status !== 'no_show' &&
        party.status !== 'completed' &&
        party.status !== 'in_service' && (
          <button
            className={classNames(
              styles.entryAction,
              styles.entryActionButton,
              buttonStyles.redBtn
            )}
            onClick={() => {
              dispatch(setSelectedPartyId(party.id))
              setShowPartyActionModal('remove')
            }}
          >
            <div className="flex gap-2 justify-center align-middle items-center">
              <img src="/icons/remove-action.svg" alt="Remove" />
              {isDesktopUser && <span>Remove</span>}
            </div>
          </button>
        )}

      <button
        className={classNames(styles.entryActionButton, buttonStyles.grayBtn)}
        onClick={() => {
          dispatch(setSelectedPartyId(party.id))
          dispatch(setIsSlideOpen(true))
        }}
      >
        <img
          className={styles.visibleAllBreakpoints}
          src="/icons/edit-action.svg"
          alt="Edit"
        />
      </button>
    </>
  )
}
