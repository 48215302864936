interface Props {
  text: string
  className?: string
}

export default function ParagraphWithLines({ text, className }: Props) {
  return (
    <p className={className}>
      {typeof text === 'string' && text
        .trim()
        .split("\n")
        .map((x, i) => (
          <span key={i}>
            {x}
            <br />
          </span>
        ))}
    </p>
  )
}
