import { dayjs } from '@/utils/dayjs'

/**
 * Managing bookings filter state
 */

export interface BookingTimeRange {
  count?: number | string
  label: string
  name: string
  startDate?: string
  endDate?: string
}

export interface BookingTimeRangeName {
  name: BookingTimeRange['name']
  label: BookingTimeRange['label']
}

export const BookingTimeRangeNames: BookingTimeRangeName[] = [
  {
    name: 'today',
    label: 'Today',
  },
  {
    name: 'tomorrow',
    label: 'Tomorrow',
  },
  {
    name: 'next7',
    label: 'Next 7 Days',
  },
  {
    name: 'next30',
    label: 'Next 30 Days',
  },
  {
    name: 'allFuture',
    label: 'All Future',
  },
]

export const getBookingTimeRangeDates = (
  name: BookingTimeRange['name'],
  timezone: string
): {
    startDate: BookingTimeRange['startDate'],
    endDate: BookingTimeRange['endDate']
} => {
  
  const startDate = name === 'tomorrow' ?
    dayjs().tz(timezone).add(1, 'day').startOf('day').format('YYYY-MM-DD') :
    dayjs().tz(timezone).startOf('day').format('YYYY-MM-DD')
  
  if (name === 'today') {
    return {
      startDate,
      endDate: startDate,
    }
  }

  if (name === 'tomorrow') {
    return {
      startDate,
      endDate: startDate,
    }
  }

  if (name === 'next7') {
    return {
      startDate,
      endDate: dayjs().tz(timezone).add(7, 'day').startOf('day').format('YYYY-MM-DD')
    }
  }

  if (name === 'next30') {
    return {
      startDate,
      endDate: dayjs().tz(timezone).add(30, 'day').startOf('day').format('YYYY-MM-DD')
    }
  }

  if (name === 'allFuture') {
    return {
      startDate,
      endDate: null,
    }
  }
}

export const buildBookingTimeRangeQuery = (rangeNames: Array<BookingTimeRangeName>, timezone: string, withOutLabels?: boolean) => {
  const currTimezone = timezone || 'America/Los_Angeles'
  return rangeNames.map((rangeName) => {
    const { name, label } = rangeName
    const { startDate, endDate } = getBookingTimeRangeDates(name, currTimezone)
    return {
      name,
      // label,
      startDate,
      endDate,
    }
  })
}

export const getPartyEndsBeforeFromRangeStartDate = (dateTime: string) => {
  if (dateTime) {
    return dayjs(dateTime).endOf('day').format('YYYY-MM-DD HH:mm:ss')
  }
  return null
}

export const getPartyStartsAfterFromRangeStartDate = (dateTime: string) => {
  if (dateTime) {
    return dayjs(dateTime).startOf('day').format('YYYY-MM-DD HH:mm:ss')
  }
  return null
}