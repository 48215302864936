import Head from 'next/head'
import Alert from '@/components/common/Alert'
import Backdrop from '@/components/common/Backdrop'
import { useDispatch } from 'react-redux'
import useLocations from '@/hooks/api-hooks/useLocations'
import useAccount from '@/hooks/api-hooks/useAccount'
import { ReactNode, useEffect, useMemo } from 'react'
import {
  setSelectedBusinessId,
  setSelectedIndustryId,
  setSelectedLocationId,
  setSelectedUserRole,
} from '@/store/slices/selectedSlice'
import {
  usePhoneOnlyQuery,
  useTabletPortraitOnly,
} from '@/utils/responsive-queries'
import {
  setIsSearchbarOpen,
  setIsSidebarOpen,
  setIsSlideOpen,
} from '@/store/slices/uiSlice'
import { LocalStorageKeys } from '@/consts/local-storage-keys'
import { useTypedSelector } from '@/store/index'
import { useEffectOnce } from 'react-use'
import SidebarNav from '../common/sidebar/SidebarNav'
import HeaderNav from '../common/HeaderNav/HeaderNav'
import { useRouter } from 'next/router'
import { setPartiesFilter } from '@/store/slices/pages/waitlistSlice'
import {
  setHasOnlyOneLocation,
  setIsUsingSharedSettings,
} from '@/store/slices/pages/manageSlice'
import useBusiness from '@/hooks/api-hooks/useBusiness'
import usePageLoading from '@/hooks/usePageLoading'
import FeatureGateDialog from '../dialogs/FeatureGateDialog'
import useSelfCheckIns from '@/hooks/api-hooks/useSelfCheckIns'
import useVirtualWaitingRooms from '@/hooks/api-hooks/useVirtualWaitingRooms'
import useNotificationSettings from '@/hooks/api-hooks/useNotificationSettings'
import Banner from '../common/Banner/Banner'
import useSubscriptionMessages from '@/hooks/useSubscriptionMessages'
import { setBanner } from '@/store/slices/bannerSlice'
import { ErrorBoundary } from '../common/ErrorBoundary'
import useBannerHeight from '@/hooks/useBannerHeight'
import useStoreUserData from '@/hooks/useStoreUserData'
import useIsUsingCustomQueueSelector from '@/hooks/useIsUsingCustomQueueSelector'

interface Props {
  children: ReactNode
  shouldLoadWholePage?: boolean
  title?: string
  style?: object
  headerNavProps?: { isWaitlist?: boolean; showLocationPicker?: boolean }
}

export default function DashboardLayout({
  title = 'Taking on the wait of the world',
  children,
  shouldLoadWholePage,
  style,
  headerNavProps,
}: Props) {
  const dispatch = useDispatch()
  const router = useRouter()

  const isPhoneUser = usePhoneOnlyQuery()

  const isPageLoading = usePageLoading(shouldLoadWholePage)

  //* region All necessary data
  //TODO * instead of dispatching initial values here as a hack,
  //     * update _app.js flow and handle setting user role, business, & location in there instead
  //     * or some form of "app user" context

  const { account } = useAccount()
  const { locations } = useLocations()
  const { business } = useBusiness()
  const { selfCheckIns } = useSelfCheckIns()
  const { virtualWaitingRooms } = useVirtualWaitingRooms()
  const { notificationSettings } = useNotificationSettings()
  const { isUsingCustomQueueSelector } = useIsUsingCustomQueueSelector(false)

  useStoreUserData()

  const [accountUserRole] = useMemo(() => account?.roles || [], [account])
  const { locationId, businessId, userRole } = useTypedSelector(
    (state) => state.selected
  )
  const { isSidebarOpen } = useTypedSelector((state) => state.ui)
  const { isBannerOpen } = useTypedSelector((state) => state.banner)
  const { isMessageVisible } = useSubscriptionMessages()
  const isTabletPortrait = useTabletPortraitOnly()
  const { currentUser, currentBusiness } = useTypedSelector(
    (state) => state.user
  )

  // When going from multiple locations and deleting locations down to just 1
  // active NS, SCI and VWR with -ableType='location' may remain
  // that are no longer editable unless the location picker is left active.
  // So if any such records exist, keep the location picker visible
  // so users can set them back to use shared settings,
  // thereby deleting the orphaned records and creating a true single location business.
  // this can be removed if BE handles deleting the orphaned records if only 1 location
  // remains after location delete.
  const hasOrphanedSettingsObjects = useMemo(() => {
    const activeLocationSci = selfCheckIns.filter(
      (sci) => sci.sciableType === 'location' && !sci.deletedAt
    )
    const activeLocationNS = notificationSettings.filter(
      (ns) => ns.nsableType === 'location' && !ns.deletedAt
    )
    const activeLocationVwr = virtualWaitingRooms.filter(
      (vwr) => vwr.vwrableType === 'location' && !vwr.deletedAt
    )

    return (
      activeLocationSci.length > 0 ||
      activeLocationNS.length > 0 ||
      activeLocationVwr.length > 0
    )
  }, [selfCheckIns, virtualWaitingRooms, notificationSettings])

  //* set defaults from account
  useEffectOnce(() => {
    if (!userRole) {
      dispatch(setSelectedUserRole(accountUserRole))
    }

    const bizSwitch = localStorage.getItem(
      LocalStorageKeys.isUsingSharedSettings
    )
    dispatch(setIsUsingSharedSettings(bizSwitch === 'true'))

    const storedLocationId = localStorage.getItem(
      LocalStorageKeys.selectedLocationId
    )

    if (storedLocationId && storedLocationId !== 'undefined') {
      dispatch(setSelectedLocationId(storedLocationId))
    } else {
      //* force default selected location
      if (account && locations && !locationId) {
        dispatch(
          setSelectedLocationId(account?.locations[0] || locations[0]?.id)
        )
      }
    }
    if (!businessId) {
      dispatch(setSelectedBusinessId(account?.businessId))
    }
  })

  useEffect(() => {
    if (router.isReady) {
      dispatch(setIsSlideOpen(false))
      dispatch(setIsSidebarOpen(false))
      dispatch(setIsSearchbarOpen(false))
      dispatch(setPartiesFilter({}))
    }
  }, [router])

  useEffect(() => {
    if (account?.locations.length === 1 && !hasOrphanedSettingsObjects) {
      dispatch(setHasOnlyOneLocation(true))
      dispatch(setIsUsingSharedSettings(false))
    } else {
      dispatch(setHasOnlyOneLocation(false))
    }
  }, [account, hasOrphanedSettingsObjects])

  useEffect(() => {
    dispatch(setSelectedIndustryId(business?.industryId))
  }, [business])

  const sidebarWidth = isSidebarOpen ? 270 : 80
  const bannerHeight = useBannerHeight()

  return (
    <>
      <Head>
        <title>{title ? `${title} | ` : ''}NextMe</title>
      </Head>

      <div className={`flex flex-col h-screen`} style={style}>
        <div className={`flex-1`} style={{ paddingTop: `${bannerHeight}px` }}>
          {isMessageVisible && (
            <ErrorBoundary fallback={''}>
              <Banner />
            </ErrorBoundary>
          )}
          <HeaderNav
            title={title}
            isBannerVisible={isBannerOpen}
            {...headerNavProps}
          />
          <SidebarNav isBannerVisible={isBannerOpen} />
          <main
            className={`h-full transition-[margin-left_padding-left] duration-200 ease-in-out pt-20`}
            style={{
              marginLeft: isPhoneUser ? 0 : sidebarWidth,
              width: isPhoneUser ? '100%' : `calc(100%-${sidebarWidth}px)`,
            }}
          >
            {!isPageLoading && children}
            <Alert />
            <FeatureGateDialog />
            <Backdrop />
          </main>
        </div>
      </div>
    </>
  )
}
