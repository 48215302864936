import { useEffect, useState } from "react";

const useAudioTone = (toneKey: string): HTMLAudioElement => {
    const [tone, setTone] = useState(null);
    
    const createAudioElement = () => {
        setTone(new Audio(toneKey))
    }
    useEffect(() => {
        createAudioElement();
    }, [])

    return tone;
}

export default useAudioTone;