import useBusiness from "./api-hooks/useBusiness";
import useSelectedLocation from "./api-selected-hooks/useSelectedLocation";

const useTimezone = () => {
  const { business } = useBusiness()
  const { selectedLocation } = useSelectedLocation()

  return selectedLocation?.timezone || business?.timezone
}

export default useTimezone