import { setPartiesFilter } from "@/store/slices/pages/waitlistSlice"
import {
  setIsBackdropOpen,
  setIsSearchbarOpen,
  setIsSidebarOpen,
  setIsSlideOpen,
} from "@/store/slices/uiSlice"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useEffectOnce } from "react-use"

export default (shouldLoadWholePage: boolean) => {
  const dispatch = useDispatch()
  const router = useRouter()

  //* Return UI extras to default state on page load or navigation
  useEffect(() => {
    if (router.isReady) {
      dispatch(setIsSlideOpen(false))
      dispatch(setIsSidebarOpen(false))
      dispatch(setIsSearchbarOpen(false))
      dispatch(setPartiesFilter({}))
    }
  }, [router])

  const [isPageLoading, setIsPageLoading] = useState(false)

  useEffectOnce(() => {
    //* the whole page is only allowed to show a loading state on initial load
    //* if more loading visuals are needed, use Suspense and/or Skeleton
    if (shouldLoadWholePage) {
      setIsPageLoading(true)
    }
  })

  useEffect(() => {
    if (isPageLoading && !shouldLoadWholePage) {
      setIsPageLoading(false)
    }
  }, [isPageLoading, shouldLoadWholePage])

  useEffect(() => {
    dispatch(setIsBackdropOpen(isPageLoading))
  }, [dispatch, isPageLoading])

  return isPageLoading
}
