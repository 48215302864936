import { refreshIntervals } from "@/consts/swr"
import { getIndustry } from "@/api/nextme/industries"
import useSWR from "swr"
import { Industry } from "@/api/interface/industry"
import { useTypedSelector } from "@/store/index"

export default function useIndustry(id?: Industry["id"]) {
  const { industryId } = useTypedSelector((state) => state.selected)

  const {
    data: industry,
    error,
    isValidating,
    mutate,
  } = useSWR(
    `/nextme/industry/${id || industryId}`,
    () => (id || industryId ? getIndustry(id || industryId) : ({} as Industry)),
    {
      dedupingInterval: 10000,
      fallbackData: {} as Industry,
      refreshInterval: refreshIntervals.verySlow,
      revalidateOnFocus: false,
    }
  )

  return {
    error,
    isValidating,
    industry,
    mutateIndustries: mutate,
  }
}
