import { useEffect, useState, useMemo } from "react"
import { useTypedSelector } from "../store"

const useBannerHeight = () => {
  const { isBannerOpen } = useTypedSelector(state => state.banner)
  const [screenSize, setScreenSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])

  const bannerHeight = useMemo(() => {
    if (isBannerOpen && screenSize.width < 768) {
      return 0
    }
    if (isBannerOpen && screenSize.width >= 768 && screenSize.width < 1024) {
      return 80
    }
    if (isBannerOpen && screenSize.width >= 1024) {
      return 60
    }
    return 0
  }, [isBannerOpen, screenSize.width])

  return bannerHeight
}

export default useBannerHeight