import useSWR from "swr"
import { refreshIntervals } from "@/consts/swr"
import { getWaitlists } from "@/api/nextme/waitlist"
import { useTypedSelector } from "@/store/index"

export default function useWaitlists() {

  const { businessId } = useTypedSelector((state) => state.selected)

  const {
    data: waitlists,
    error,
    isValidating,
    isLoading,
    mutate: mutateWaitlists,

  } = useSWR(
    `/nextme/waitlists/business_id_${businessId}`,
    () => getWaitlists(),
    {
      dedupingInterval: 10000,
      fallbackData: [],
      refreshInterval: refreshIntervals.quicker,
      revalidateOnFocus: true,
    }
    )
  
  
  return {
    waitlists,
    error,
    isValidating,
    isLoading,
    mutateWaitlists,
  }
}