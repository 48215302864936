import { useMemo } from "react"
import { getSelectedObjectById } from "@/utils/util-functions"
import { DEFAULT_QUEUE_FILTER } from "@/consts/misc"
import { Queue } from "@/api/interface/queue"
import { useTypedSelector } from "@/store/index"

export default function useSelectedQueue(queues: Array<Queue>) {
  const { queueId } = useTypedSelector((state) => state.selected)

  const selectedQueue: Partial<Queue> = useMemo(
    () =>
      queueId ? getSelectedObjectById(queueId, queues) : DEFAULT_QUEUE_FILTER,
    [queueId, queues]
  )

  return {
    selectedQueue,
  }
}
