import useSWR from "swr"
import { getBusiness } from "@/api/nextme/businesses"
import { refreshIntervals } from "@/consts/swr"
import { useTypedSelector } from "@/store/index"

export default function useBusiness() {
  const { businessId } = useTypedSelector((state) => state.selected)

  const {
    data: business,
    isValidating,
    isLoading,
    mutate: mutateBusiness,
    error,
  } = useSWR(
    businessId ? `/nextme/business/${businessId}/` : null,
    () => getBusiness(businessId, { include: ["address", "media", "subscriptions"] }),
    {
      refreshInterval: refreshIntervals.standard,
      dedupingInterval: 10000,
      revalidateOnFocus: false,
    }
  )

  return {
    isValidating,
    business,
    isLoading,
    mutateBusiness,
    error,
  }
}
