import { useTypedSelector } from '../store'
import useWaitTimesByLocationId from './api-hooks/useWaitTimesByLocationId'
import useSelectedLocation from './api-selected-hooks/useSelectedLocation'
import useWaitlistUpdates from './useWaitlistUpdates'
import { useEffect, useMemo, useState } from 'react'
import { mapqueueselectOptions } from '@/utils/queue-utils'
import { Party } from '@/api/interface/party'
import useCustomFields from './api-hooks/useCustomFields'
import { CustomFieldOption } from '@/api/interface/custom-field'

export interface QueueSelectorListboxArgs {
  queueName?: string
  party?: Partial<Party>
}

export interface SelectorListboxOption {
  label: string | number
  value: string
  data?: any
}

export interface QueueSelectorListboxState {
  isSubmitting: boolean
  setIsSubmitting: Function
  isPopoverEnabled: boolean
  queueMenuOptions?: SelectorListboxOption[]
  queueValue?: SelectorListboxOption
  setQueueValue: Function
  mutateWaitTimes: Function
}

export default function useQueueSelectorListbox({ queueName, party }) {
  const { isHistory } = useTypedSelector(
    (state) => state.pages.waitlist.filters
  )
  const { isSubmitting, setIsSubmitting } = useWaitlistUpdates()

  const { selectedLocation } = useSelectedLocation()
  const { waitTimes, mutateWaitTimes } = useWaitTimesByLocationId(
    selectedLocation?.id
  )

  const { customFields } = useCustomFields()

  const customQueueSelectField = useMemo(() => {
    return customFields?.find((cf) => cf.type === 'queueselect')
  }, [customFields])

  const queueMenuOptions = useMemo(() => {
      const mappedOptions = mapqueueselectOptions(selectedLocation?.enabledQueues, waitTimes)
      
      const cleanedOptions = mappedOptions?.map((option) => {
        return {
          value: option.value,
          label: option.name,
        }
      })
      return cleanedOptions

  }, [selectedLocation, waitTimes, customQueueSelectField])

  const defaultQueuValue = useMemo(() => {

    const label = waitTimes?.find(
      (wt) => wt.queueId === party?.queueId
    )?.queueName
    const value = party?.queueId
    return {
      label,
      value,
    }
  }, [queueMenuOptions, party, queueName])
  const [queueValue, setQueueValue] = useState<SelectorListboxOption>(null)

  useEffect(() => {
    if (!queueValue) {
      setQueueValue(defaultQueuValue)
    }
  }, [defaultQueuValue, queueValue])

  const listboxLabel = useMemo(() => {
    return 'queue'
  }, [customQueueSelectField])

  const isPopoverEnabled = useMemo(() => {
    return !isHistory && queueMenuOptions?.length > 1
  }, [queueMenuOptions, queueValue])

  return {
    isSubmitting,
    setIsSubmitting,
    isPopoverEnabled,
    listboxLabel,
    queueMenuOptions,
    queueValue,
    setQueueValue,
    mutateWaitTimes,
  }
}
