import { useMemo } from "react"
import useCustomFieldsByBusinessId from "@/hooks/api-hooks/useCustomFieldsByBusinessId"
import useCustomFieldsByLocationId from "@/hooks/api-hooks/useCustomFieldsByLocationId"

const useCustomFields = () => {
  // get all custom fields for this user, including 'business' and 'location' types
  const {
    customFields: locationCustomFields,
    mutate: mutateLocationCustomFields,
    isValidating: isLocationCustomFieldsLoading,
  } = useCustomFieldsByLocationId()
  const {
    customFields: businessCustomFields,
    mutate: mutateBusinessCustomFields,
    isValidating: isBusinessCustomFieldsLoading,
  } = useCustomFieldsByBusinessId()

  const isValidating = useMemo(
    () => isLocationCustomFieldsLoading || isBusinessCustomFieldsLoading,
    [isLocationCustomFieldsLoading, isBusinessCustomFieldsLoading]
  )

  const combinedCustomFields = useMemo(
    () => [...businessCustomFields, ...locationCustomFields],
    [businessCustomFields, locationCustomFields, isValidating]
  )

  const mutateCustomFields = async () => {
    await mutateLocationCustomFields()
    await mutateBusinessCustomFields()
  }

  return {
    isValidating,
    customFields: combinedCustomFields,
    mutateCustomFields,
  }
}

export default useCustomFields
