import styles from './Skeleton.module.scss'
import classNames from 'classnames'
import colors from '@/styles/Colors.module.scss'

export interface SkeletonProps {
  width: string
  height: string
  borderRadius?: string
  bgColor?: string
  animation?: string
}

const Skeleton = ({
  width,
  height,
  borderRadius = '0',
  bgColor = 'gray-light',
  animation = 'pulse',
}: SkeletonProps) => {
  const classes = classNames(
    styles['skeleton'],
    animation === 'pulse' ? 'animate-pulse' : null
  )

  return (
    <span
      className={classes}
      style={{
        width,
        height,
        borderRadius,
        backgroundColor: colors[`theme-${bgColor}`],
      }}
    ></span>
  )
}

export default Skeleton
