import axios from "axios"
import { Business } from "../interface/business"
import { CustomField } from "../interface/custom-field"
import { Location } from "../interface/location"
import { Queue } from "../interface/queue"
import { APIFilterParams } from "../interface/utils"

export const getCustomFields = (params = {}) =>
  axios.get(`v2/custom-fields`, { params }).then((res) => res.data.data)

export const getCustomFieldsByLocationId = (
  locationId: Location["id"],
  params: APIFilterParams = {}
): Promise<Array<CustomField>> =>
  getCustomFields({
    filter: {
      ...params.filter,
      cfable_type: "location",
      cfableId: locationId,
    },
  })

export const getCustomFieldsByBusinessId = (
  businessId: Business["id"],
  params: APIFilterParams = {}
): Promise<Array<CustomField>> =>
  getCustomFields({
    filter: {
      ...params.filter,
      cfable_type: "business",
      cfableId: businessId,
    },
  })

export const getCustomFieldsByLocation = (
  locationId: Location["id"],
  params: APIFilterParams = {}
): Promise<Array<CustomField>> =>
  axios
    .get(`v2/locations/${locationId}/custom-fields`, {
      params,
    })
    .then((res) => res.data.data)

export const getCustomFieldsByQueue = (
  queueId: Queue["id"],
  params = {}
): Promise<Array<CustomField>> =>
  axios
    .get(`v2/queues/${queueId}/custom-fields`, {
      params,
    })
    .then((res) => res.data.data)

export const getCustomField = (
  cfId: CustomField["id"],
  params = {}
): Promise<CustomField> =>
  axios
    .get(`${`v2/custom-fields`}/${cfId}`, { params })
    .then((res) => res.data.data)

export const createCustomField = (
  customField: Partial<CustomField>
): Promise<CustomField> =>
  axios.post(`v2/custom-fields`, customField).then((res) => res?.data?.data)

export const updateCustomField = (
  customField: Partial<CustomField>
): Promise<CustomField> =>
  axios
    .patch(`${`v2/custom-fields`}/${customField.id}`, customField)
    .then((res) => res.data.data)

export const updateCustomFieldOrder = (
  customField: Partial<CustomField> | string,
  position: number
): Promise<CustomField> => {
  const id = typeof customField === 'string' ? customField : customField.id
  return axios.patch(`v2/custom-fields/${id}/reposition/${position}`)
}

export const deleteCustomField = (
  cfId: CustomField["id"],
  params = {}
): Promise<CustomField> =>
  axios
    .delete(`${`v2/custom-fields`}/${cfId}`, { params })
    .then((res) => res.data.data)
